// auth.service.ts

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationsService } from '@app/dashboard/components/shared/notifications-drop-down/notifications.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = environment.apiUrl;
  private tokenKey = 'auth_token';
  private isAuthenticated: boolean = false;
  private userIdKey = 'user_id'; // Add a key for storing UserId
  private email = 'email';
  private userName = 'userName';
  private authStateSubject = new BehaviorSubject<boolean>(
    this.getInitialAuthState(),
  );
  authStatus = this.authStateSubject.asObservable();
  private selectedUserRole: number | null = null;
  constructor(
    private http: HttpClient,
    private notificationService: NotificationsService,
    private route: ActivatedRoute,
  ) {}
  private getInitialAuthState(): boolean {
    // Check localStorage on app initialization
    return JSON.parse(localStorage.getItem('isAuthenticated') || 'false');
  }

  hasAccess(requiredRole: number): boolean {
    this.authStateSubject.next(true);
    return this.getUserRole() == requiredRole;
  }

  setToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
    this.isAuthenticated = true;
    localStorage.setItem('isAuthenticated', this.isAuthenticated.toString());
    const decodedToken: any = JSON.parse(
      this.base64UrlDecode(token.split('.')[1]),
    );

    const userId = decodedToken.UserId;
    if (userId) {
      localStorage.setItem(this.userIdKey, token);
    }
  }

  base64UrlDecode(str: string) {
    // Replace '-' with '+' and '_' with '/' to comply with base64 standard.
    let base64 = str.replace(/-/g, '+').replace(/_/g, '/');
    // Pad with '=' to make the string length a multiple of 4
    while (base64.length % 4) {
      base64 += '=';
    }
    return atob(base64);
  }
  setUserEmail(email: string): void {
    localStorage.setItem(this.email, email);
  }
  setUserName(userName: string): void {
    localStorage.setItem(this.userName, userName);
  }

  setUserRole(role: number): void {
    localStorage.setItem('role', role.toString());
  }

  getToken(): string {
    return localStorage.getItem(this.tokenKey);
  }

  getUserEmail(): string {
    return localStorage.getItem(this.email);
  }

  getUserName(): string {
    return localStorage.getItem('userName');
  }

  getUserRole(): number {
    return JSON.parse(localStorage.getItem('role'));
  }

  removeToken(): void {
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem('isAuthenticated');
  }

  removeuserEmail(): void {
    localStorage.removeItem(this.email);
  }

  removeUserName(): void {
    localStorage.removeItem(this.userName);
  }

  isUserAuthenticated(): boolean {
    return JSON.parse(localStorage.getItem('isAuthenticated'));
  }

  signIn(email: string, password: string): Observable<any> {
    this.removeToken();
    return this.http
      .post(`${this.apiUrl}/api/Account/login`, { email, password })
      .pipe(
        map((response: any) => {
          if (response.errorCode === 0) {
            const token = response.data.token;
            const email = response.data.email;
            const userName = response.data.userName;
            const role = response.data.permissions;
            const userId = response.data.userId;
            const isFirstLogin = response.data.isFirstTimeLogin;

            if (token) {
              this.setToken(token);
              this.setUserEmail(email);
              this.setUserName(userName);
              this.setUserRole(role);
              this.setUserId(userId);
              this.setIsFirstLogin(isFirstLogin);
              this.notificationService.createHubConnection(token);
            }
          }
          return response;
        }),
      );
  }

  signUp(user: any): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/api/Account/Register`, user)
      .pipe(map((response: any) => response));
  }

  uploadFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(`${this.apiUrl}/api/upload`, formData);
  }

  hasRole(role: number): boolean {
    const userRole = this.getUserRole();

    return userRole === role;
  }

  sendOtp(email: string): Observable<any> {
    this.removeToken();
    const url = `${this.apiUrl}/api/Account/ReSendOTP`;

    return this.http
      .post(url, { email })
      .pipe(map((response: any) => response));
  }

  verifyOtp(email: string, otp: string): Observable<any> {
    const url = `${this.apiUrl}/api/Account/CheckOtpCode`;

    return this.http
      .post(url, { email, otp })
      .pipe(map((response: any) => response));
  }

  changePassword(
    confirmNewPassword: string,
    newPassword: string,
  ): Observable<any> {
    const url = `${this.apiUrl}/api/Account/ResetPassword`;
    let token = localStorage.getItem('otp_token');

    if (!token) {
      token = this.route.snapshot.queryParams['token'];
    }

    return this.http.post(url, { confirmNewPassword, newPassword, token });
  }

  setUserId(userId: string): void {
    localStorage.setItem('userId', userId);
  }

  setIsFirstLogin(isFirstLogin: boolean): void {
    localStorage.setItem('isFirstLogin', isFirstLogin.toString());
  }

  getUserId(): number {
    return Number(localStorage.getItem('userId'));
  }

  logOut(): Observable<any> {
    this.authStateSubject.next(false);
    return this.http
      .get(`${this.apiUrl}/api/Account/Logout`)
      .pipe(tap(() => this.notificationService.stopHubConnection()));
  }

  setSelectedUserRole(roleId) {
    this.selectedUserRole = roleId;
    if (roleId) localStorage.setItem('selectedUserRole', roleId.toString());
    else localStorage.setItem('selectedUserRole', '');
  }

  getSelectedUserRole(): number | null {
    if (this.selectedUserRole === null) {
      const role = localStorage.getItem('selectedUserRole');
      this.selectedUserRole = role ? parseInt(role) : null;
    }
    return this.selectedUserRole;
  }
}
