import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';

interface DataItem {
  status?: string;
  count?: number;
  typeOfRequest?: string;
  numberOfDays?: number;
}

interface HeaderColumn {
  field: string;
  header: string;
}

export interface TableData {
  title: string;
  data: DataItem[];
  headers: HeaderColumn[];
}

@Injectable({
  providedIn: 'root',
})
export class TableService {
  private apiUrl = environment.apiUrl;
  private numberOfRequestsHeaders: HeaderColumn[] = [
    { field: 'status', header: 'حالة الطلب' },
    { field: 'count', header: 'عدد الطلبات' },
  ];

  private averageTimeHeaders: HeaderColumn[] = [
    { field: 'typeOfRequest', header: 'نوع الطلب' },
    { field: 'numberOfDays', header: 'عدد الأيام' },
  ];

  constructor(private http: HttpClient) {}

  // Fetch data for number of requests
  getNumberOfRequestsData(
    accreditationRequestType: number,
  ): Observable<TableData> {
    return this.http
      .get<any>(
        `${this.apiUrl}/api/SystemAdministrator/GetOverallPlatformPerformanceReport?accreditationRequestType=${accreditationRequestType}`,
      )
      .pipe(
        map((response) => {
          const data: DataItem[] = [
            {
              status: 'إجمالي الطلبات',
              count: response.data.totalApplications,
            },
            {
              status: 'الطلبات القائمة',
              count: response.data.ongoingApplications,
            },
            {
              status: 'الإعتمادات السارية',
              count: response.data.validAccreditationsCount,
            },
            {
              status: 'الإعتمادات المنتهية',
              count: response.data.expiredAccreditationsCount,
            },
            {
              status: 'الإعتمادات المعلقة',
              count: response.data.pausedAccreditationsCount,
            },
            {
              status: 'الإعتمادات الموقوفة',
              count: response.data.suspendedAccreditationsCount,
            },
            {
              status: 'الاعتمادات الملغاة',
              count: response.data.canceledAccreditationsCount,
            },
            {
              status: 'طلبات التجديد',
              count: response.data.renewalApplicationsCount,
            },
          ];
          return {
            title: 'عدد الطلبات',
            data,
            headers: this.numberOfRequestsHeaders,
          };
        }),
      );
  }

  getAverageTimeDataByOrg(
    accreditationRequestType: number,
  ): Observable<TableData> {
    return this.http
      .get<any>(
        `${this.apiUrl}/api/SystemAdministrator/GetOverallPlatformPerformanceReport?accreditationRequestType=${accreditationRequestType}`,
      )
      .pipe(
        map((response) => {
          const data: DataItem[] = [
            {
              typeOfRequest:
                'البرامج التدريبية في مجال كفاءة الطاقة التي قامت الجهات التدريبية بتأليفها',
              numberOfDays:
                response.data.avgAccreditationProcessingTimeByOrgInDays,
            },
          ];
          return {
            title: 'متوسط وقت استغراق طلبات الإعتماد',
            data,
            headers: this.averageTimeHeaders,
          };
        }),
      );
  }

  getAverageTimeDataByCenter(
    accreditationRequestType: number,
  ): Observable<TableData> {
    return this.http
      .get<any>(
        `${this.apiUrl}/api/SystemAdministrator/GetOverallPlatformPerformanceReport?accreditationRequestType=${accreditationRequestType}`,
      )
      .pipe(
        map((response) => {
          const data: DataItem[] = [
            {
              typeOfRequest:
                'البرامج التدريبية في مجال كفاءة الطاقة التي عمل المركز على إعدادها',
              numberOfDays:
                response.data.avgAccreditationProcessingTimeByCenterInDays,
            },
          ];
          return {
            title: 'متوسط وقت استغراق طلبات الإعتماد',
            data,
            headers: this.averageTimeHeaders,
          };
        }),
      );
  }
}
