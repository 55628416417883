import { Component, OnInit } from '@angular/core';
import { Assets } from '@assets/images';
import { MeasuringIndicatorsService } from '@app/dashboard/services/measuring-indicators.service';
import {
  TableService,
  TableData,
} from '@app/dashboard/services/table-data.service';
import { ChartType } from '@app/types/chart-reports';

interface ChartData {
  labels?: string[];
  datasets?: any[];
}

@Component({
  selector: 'app-measuring-indicators',
  templateUrl: './measuring-indicators.component.html',
  styleUrl: './measuring-indicators.component.scss',
})
export class MeasuringIndicatorsComponent implements OnInit {
  // Make enum accessible in the template
  ChartType = ChartType;

  banner = Assets.banner;
  activeTabIndex: number = 0;

  // Chart display types
  chartTypeActiveUsers = 'bar';
  chartTypeCompletionRate = 'pie';
  chartTypeWithdrawalRate = 'bar';

  // Use a dynamic object to manage years for each chart type
  years: { [key in ChartType]: number } = {
    [ChartType.ActiveUsers]: new Date().getFullYear(),
    [ChartType.ApplicationCompletion]: new Date().getFullYear(),
    [ChartType.WithdrawnApplications]: new Date().getFullYear(),
  };

  // Labels for each chart type
  reportLabels: { [key in ChartType]: string } = {
    [ChartType.ActiveUsers]: 'عدد المستخدمين النشطين',
    [ChartType.ApplicationCompletion]: 'نسبة إكمال الطلبات',
    [ChartType.WithdrawnApplications]: 'معدل انسحاب الجهات من التقديم',
  };

  // Chart data for each chart type
  activeUsersReport: ChartData | null = null;
  applicationCompletionRate: ChartData | null = null;
  withdrawnApplicationRate: ChartData | null = null;

  // Table data
  durationOfAccredition: TableData | null = null;
  durationOfSubmission: TableData | null = null;
  numberOfRequestsTable: TableData | null = null;

  constructor(
    private measuringIndicatorsService: MeasuringIndicatorsService,
    private tableService: TableService,
  ) {}

  ngOnInit(): void {
    this.loadData(this.mapAccreditationRequestType(this.activeTabIndex));
  }

  onTabChange(event: { index: number }): void {
    this.activeTabIndex = event.index;
    this.loadData(this.mapAccreditationRequestType(this.activeTabIndex));
  }

  mapAccreditationRequestType(tabIndex: number): number {
    return tabIndex === 0 ? 2 : 1;
  }

  loadData(accreditationRequestType: number): void {
    // Clear previous data before loading new data
    this.clearChartData();

    // Load all charts
    this.loadReport(ChartType.ActiveUsers);
    this.loadReport(ChartType.ApplicationCompletion);
    this.loadReport(ChartType.WithdrawnApplications);

    // Load table data
    this.measuringIndicatorsService
      .getDurationOfsubmitRequest(accreditationRequestType)
      .subscribe(
        (data) => (this.durationOfAccredition = data),
        (error) =>
          console.error('Error fetching DurationOfAccredition data:', error),
      );

    this.measuringIndicatorsService
      .getAverageTimeData(accreditationRequestType)
      .subscribe(
        (data) => (this.durationOfSubmission = data),
        (error) =>
          console.error('Error fetching DurationOfSubmission data:', error),
      );

    this.tableService
      .getNumberOfRequestsData(accreditationRequestType)
      .subscribe(
        (data) => (this.numberOfRequestsTable = data),
        (error) =>
          console.error('Error fetching numberOfRequestsTable data:', error),
      );
  }

  // Load the report data for a given chart type.
  loadReport(chart: ChartType): void {
    const year = this.years[chart];
    const accreditationRequestType = this.mapAccreditationRequestType(
      this.activeTabIndex,
    );

    let request$;

    switch (chart) {
      case ChartType.ActiveUsers:
        // Active Users only needs year
        request$ = this.measuringIndicatorsService.getActiveUsersData(year);
        break;
      case ChartType.ApplicationCompletion:
        // Application Completion needs accreditationRequestType and year
        request$ = this.measuringIndicatorsService.getApplicationCompletionRate(
          accreditationRequestType,
          year,
        );
        break;
      case ChartType.WithdrawnApplications:
        // Withdrawn Applications needs accreditationRequestType and year
        request$ = this.measuringIndicatorsService.getWithDrawApplicationData(
          accreditationRequestType,
          year,
        );
        break;
      default:
        console.error('Unknown chart type');
        return;
    }

    request$.subscribe(
      (data: any) => {
        switch (chart) {
          case ChartType.ActiveUsers:
            this.activeUsersReport = data.chart;
            break;
          case ChartType.ApplicationCompletion:
            this.applicationCompletionRate = data.chart;
            break;
          case ChartType.WithdrawnApplications:
            this.withdrawnApplicationRate = data.chart;
            break;
        }
      },
      (error: any) => {
        console.error(`Error loading ${ChartType[chart]} data:`, error);
      },
    );
  }

  nextYear(chart: ChartType): void {
    this.changeYear(chart, 1);
  }

  previousYear(chart: ChartType): void {
    this.changeYear(chart, -1);
  }

  // Adjust the year for a given chart and reload the data.

  private changeYear(chart: ChartType, increment: number): void {
    const newYear = this.years[chart] + increment;
    const currentYear = new Date().getFullYear();

    // Prevent future years
    if (newYear > currentYear) {
      return;
    }

    this.years[chart] = newYear;
    this.loadReport(chart);
  }

  // Clear all chart and table data before loading new data.
  private clearChartData(): void {
    this.activeUsersReport = null;
    this.applicationCompletionRate = null;
    this.withdrawnApplicationRate = null;
    this.durationOfAccredition = null;
    this.durationOfSubmission = null;
    this.numberOfRequestsTable = null;
  }
}
