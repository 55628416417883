export enum ReportCategory {
  AnnualReport = 1,
  SemiAnnualReport = 2,
  QuarterReport = 3,
}
export enum ChartType {
  ActiveUsers = 1,
  ApplicationCompletion,
  WithdrawnApplications,
}
