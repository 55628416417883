<loading-spinner></loading-spinner>
<div class="form-tabs__containr" *ngIf="!showRejectionReason">
  <app-custom-button
    buttonText="مسببات الرفض"
    buttonStyle="transperantGreenButton"
    *ngIf="isRejectedApplication"
    class="rejection-reasons-button"
    (click)="showRejectionReason = true; showRejectionEmitter.emit(true)"
  />
  <form
    *ngIf="!(isLoading | async)"
    [formGroup]="accreditationForm"
    class="custom-form"
  >
    <div class="tab-content" tabContent>
      <p-tabView [(activeIndex)]="activeTabIndex">
        <p-tabPanel header="بيانات الجهة التدريبية">
          <div
            class="tab-data"
            *ngIf="
              id && !edittingTrainingOrgTab && !isDraft;
              else trainingOrgForm
            "
          >
            <div class="tab-title-container">
              <app-page-title title="trainingBodyData" />
              <img
                *ngIf="isEditableApplication"
                (click)="edittingTrainingOrgTab = true"
                class="edit-tab-icon"
                src="assets/images/edit-notes.svg"
              />
            </div>

            <div class="row-data">
              <div class="training-body__info">
                <div class="field-container">
                  <app-svg-icon
                    class="field-icon"
                    name="users-icon"
                  ></app-svg-icon>
                  <div class="field-name">اسم الجهة التدريبية</div>
                </div>
                <div class="field-value">
                  {{ requestData.trainingOrganization.organizationName }}
                </div>
              </div>
              <div class="training-body__info">
                <div class="field-container">
                  <app-svg-icon
                    class="field-icon"
                    name="document-icon"
                  ></app-svg-icon>
                  <div class="field-name">نوع الطلب</div>
                </div>
                <div class="field-value">
                  {{ requestData.trainingOrganization.requestTypeName }}
                </div>
              </div>
            </div>
            <div class="row-data">
              <div class="training-body__info">
                <div class="field-container">
                  <app-svg-icon
                    class="field-icon"
                    name="date-icon"
                  ></app-svg-icon>
                  <div class="field-name">
                    تاريخ رخصة المؤسسة العامة للتدريب التقني والمهني
                  </div>
                </div>
                <div class="field-value">
                  {{ requestData.trainingOrganization.licenseDate }}
                </div>
              </div>
              <div class="training-body__info">
                <div class="field-container">
                  <app-svg-icon
                    class="field-icon"
                    name="location-pin-icon"
                  ></app-svg-icon>
                  <div class="field-name">المدينة</div>
                </div>
                <div class="field-value">
                  {{ requestData.trainingOrganization.cityName }}
                </div>
              </div>
            </div>
            <div class="row-data">
              <div class="training-body__info">
                <div class="field-container">
                  <app-svg-icon
                    class="field-icon"
                    name="phone-icon"
                  ></app-svg-icon>
                  <div class="field-name">رقم الجوال</div>
                </div>
                <div class="field-value">
                  {{ requestData.trainingOrganization.phoneNumber }}
                </div>
              </div>
              <div class="training-body__info">
                <div class="field-container">
                  <app-svg-icon
                    class="field-icon"
                    name="date-icon"
                  ></app-svg-icon>
                  <div class="field-name">
                    تاريخ انتهاء رخصة المؤسسة العامة للتدريب التقني والمهني
                  </div>
                </div>
                <div class="field-value">
                  {{ requestData.trainingOrganization.licenseExpirationDate }}
                </div>
              </div>
            </div>
            <div class="row-data">
              <div class="training-body__info">
                <div class="field-container">
                  <app-svg-icon
                    class="field-icon"
                    name="location-pin-icon"
                  ></app-svg-icon>
                  <div class="field-name">العنوان</div>
                </div>
                <div class="field-value">
                  {{ requestData.trainingOrganization.address }}
                </div>
              </div>
              <div class="training-body__info">
                <div class="field-container">
                  <app-svg-icon
                    class="field-icon"
                    name="email-icon"
                  ></app-svg-icon>
                  <div class="field-name">البريد الإلكتروني</div>
                </div>
                <div class="field-value">
                  {{ requestData.trainingOrganization.email }}
                </div>
              </div>
            </div>
            <div class="row-data">
              <div class="training-body__info">
                <div class="field-container">
                  <app-svg-icon
                    class="field-icon"
                    name="license-icon"
                  ></app-svg-icon>
                  <div class="field-name">
                    رقم رخصة المؤسسة العامة للتدريب التقني والمهني
                  </div>
                </div>
                <div class="field-value">
                  {{ requestData.trainingOrganization.trainingLicenseNumber }}
                </div>
              </div>
              <div class="training-body__info">
                <div class="field-container">
                  <app-svg-icon
                    class="field-icon"
                    name="user-icon"
                  ></app-svg-icon>
                  <div class="field-name">اسم المنسق</div>
                </div>
                <div class="field-value">
                  {{ requestData.trainingOrganization.coordinatorName }}
                </div>
              </div>
            </div>
          </div>

          <ng-template #trainingOrgForm>
            <span
              class="user-guide"
              *ngIf="isTrainingOrganization() && !edittingTrainingOrgTab"
              >الرجاء الاطلاع على الدليل الإرشادي قبل تعبئة الحقول (<a
                (click)="userGuideManual()"
              >
                الدليل الإرشادي</a
              >)</span
            >
            <div
              class="tab-content__container"
              [ngClass]="{
                'tab-data': id && edittingTrainingOrgTab && !isDraft,
              }"
            >
              <app-page-title
                title="trainingBodyData"
                customClass="form-tab-title"
                *ngIf="id && !isDraft"
              />
              <div class="form-row">
                <div class="form-col">
                  <text-field
                    id="agencyName"
                    [label]="'trainingBodyName' | translate"
                    [control]="accreditationForm?.get('agencyName')"
                    [isRequired]="true"
                    [errorMessage]="
                      accreditationForm?.get('agencyName').errors?.pattern
                        ? 'ادخل حروفاً بالعربية و الإنجليزية فقط'
                        : 'هذا الحقل المطلوب'
                    "
                  ></text-field>
                </div>

                <div class="form-col">
                  <dropdown
                    [options]="accreditationApplicationTypes"
                    id="applicationType"
                    [isRequired]="true"
                    [placeholder]="'الرجاء الاختيار من القائمة المنسدلة'"
                    [label]="'نوع الطلب' | translate"
                    [control]="accreditationForm?.get('applicationType')"
                  >
                  </dropdown>
                </div>
              </div>
              <div class="form-row">
                <div class="form-col">
                  <date-picker
                    id="licenseDate"
                    [label]="
                      'تاريخ رخصة المؤسسة العامة للتدريب التقني والمهني'
                        | translate
                    "
                    [control]="accreditationForm?.get('licenseDate')"
                    [isRequired]="true"
                    [customErrorMessage]="
                      licenseDateError ? 'هذا الحقل مطلوب' : ''
                    "
                    [maxDate]="licenseMaxDate"
                  />
                </div>
                <div class="form-col">
                  <dropdown
                    [options]="cities"
                    id="city"
                    [label]="'المدينة' | translate"
                    [control]="accreditationForm?.get('city')"
                    [isRequired]="true"
                    [placeholder]="'الرجاء الاختيار من القائمة المنسدلة'"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-col">
                  <text-field
                    id="mobileNumber"
                    keyFilter="int"
                    [label]="'رقم الجوال' | translate"
                    [control]="accreditationForm?.get('mobileNumber')"
                    [isRequired]="true"
                  ></text-field>
                </div>
                <div class="form-col">
                  <date-picker
                    id="licenseExpiryDate"
                    [label]="
                      'تاريخ انتهاء رخصة المؤسسة العامة للتدريب التقني والمهني'
                        | translate
                    "
                    [control]="accreditationForm?.get('licenseExpiryDate')"
                    [isRequired]="true"
                    [minDate]="licenseExpiryMinDate"
                    [customErrorMessage]="
                      licenseExpiryDateError ? 'هذا الحقل مطلوب' : ''
                    "
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-col">
                  <text-field
                    id="address"
                    [label]="'العنوان' | translate"
                    [control]="accreditationForm?.get('address')"
                    [isRequired]="true"
                    [errorMessage]="
                      accreditationForm?.get('address').errors?.pattern
                        ? 'ادخل حروفاً بالعربية و الإنجليزية فقط'
                        : 'هذا الحقل المطلوب'
                    "
                  ></text-field>
                </div>
                <div class="form-col">
                  <text-field
                    id="email"
                    [label]="'البريد الالكتروني' | translate"
                    [control]="accreditationForm?.get('email')"
                    [isRequired]="true"
                    placeholder="example@example.com"
                    errorMessage="الرجاء إدخال بريد إلكتروني صحيح"
                  ></text-field>
                </div>
              </div>
              <div class="form-row">
                <div class="form-col">
                  <text-field
                    id="licenseNumber"
                    [label]="
                      'رقم رخصة المؤسسة العامة للتدريب التقني والمهني'
                        | translate
                    "
                    [control]="accreditationForm?.get('licenseNumber')"
                    [isRequired]="true"
                  />
                </div>

                <div class="form-col">
                  <text-field
                    id="cordinatorName"
                    [label]="'اسم المنسق' | translate"
                    [control]="accreditationForm?.get('cordinatorName')"
                    [isRequired]="true"
                  ></text-field>
                </div>
              </div>
              <div *ngIf="isEditableApplication" class="tab-buttons-container">
                <app-custom-button
                  [disabled]="isTrainingOrgSaveButtonDisabled"
                  buttonText="حفظ"
                  buttonStyle="greenButton"
                  (click)="onUpdateTrainingOrganizationSection()"
                />
                <app-custom-button
                  buttonText="رجوع"
                  buttonStyle="transperantGreenButton"
                  (click)="resetTrainingOrgDataTab()"
                />
              </div>
            </div>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel header="بيانات البرنامج التدريبي">
          <div
            class="tab-data"
            *ngIf="
              id && !edittingTrainingProgramTab && !isDraft;
              else trainingProgramForm
            "
          >
            <div class="tab-title-container">
              <app-page-title title="بيانات البرنامج التدريبي" />
              <img
                *ngIf="isEditableApplication"
                (click)="edittingTrainingProgramTab = true"
                class="edit-tab-icon"
                src="assets/images/edit-notes.svg"
              />
            </div>

            <div class="row-data">
              <div class="training-body__info">
                <div class="field-container">
                  <app-svg-icon
                    class="field-icon"
                    name="document-icon"
                  ></app-svg-icon>
                  <div class="field-name">اسم البرنامج التدريبي</div>
                </div>
                <div class="field-value">
                  {{ requestData.trainingProgram.nameAr }}
                </div>
              </div>
              <div class="training-body__info">
                <div class="field-container">
                  <app-svg-icon
                    class="field-icon"
                    name="document-icon"
                  ></app-svg-icon>
                  <div class="field-name">
                    اسم البرنامج التدريبي باللغة الإنجليزية
                  </div>
                </div>
                <div class="field-value">
                  {{ requestData.trainingProgram.nameEn }}
                </div>
              </div>
            </div>
            <div class="row-data">
              <div class="training-body__info">
                <div class="field-container">
                  <app-svg-icon
                    class="field-icon"
                    name="note-icon"
                  ></app-svg-icon>
                  <div class="field-name">نوع البرنامج التدريبي</div>
                </div>
                <div class="field-value">
                  {{ requestData.trainingProgram.trainingProgramType }}
                </div>
              </div>
              <div class="training-body__info">
                <div class="field-container">
                  <app-svg-icon
                    class="field-icon"
                    name="badge-icon"
                  ></app-svg-icon>
                  <div class="field-name">مستوى البرنامج التدريبي</div>
                </div>
                <div class="field-value">
                  {{ requestData.trainingProgram.programLevelName }}
                </div>
              </div>
            </div>
            <div class="row-data">
              <div class="training-body__info">
                <div class="field-container">
                  <app-svg-icon
                    class="field-icon"
                    name="clock-icon"
                  ></app-svg-icon>
                  <div class="field-name">مدة البرنامج التدريبي (بالأيام )</div>
                </div>
                <div class="field-value">
                  {{ requestData.trainingProgram.trainingProgramDuration }}
                </div>
              </div>
              <div class="training-body__info">
                <div class="field-container">
                  <app-svg-icon
                    class="field-icon"
                    name="clock-icon"
                  ></app-svg-icon>
                  <div class="field-name">عدد الساعات التدريبية الإجمالية</div>
                </div>
                <div class="field-value">
                  {{ requestData.trainingProgram.totalTrainingHours }}
                </div>
              </div>
            </div>
            <div class="row-data">
              <div class="training-body__info">
                <div class="field-container">
                  <app-svg-icon
                    class="field-icon"
                    name="target-icon"
                  ></app-svg-icon>
                  <div class="field-name">الهدف العام من البرنامج التدريبي</div>
                </div>
                <div class="field-value">
                  {{ requestData.trainingProgram.programObjective }}
                </div>
              </div>
              <div class="training-body__info">
                <div class="field-container">
                  <app-svg-icon
                    class="field-icon"
                    name="globe-icon"
                  ></app-svg-icon>
                  <div class="field-name">لغة البرنامج التدريبي</div>
                </div>
                <div class="field-value">
                  {{ requestData.trainingProgram.languageLookupName }}
                </div>
              </div>
            </div>
            <div class="row-data">
              <div class="training-body__info">
                <div class="field-container">
                  <app-svg-icon
                    class="field-icon"
                    name="users-icon"
                  ></app-svg-icon>
                  <div class="field-name">الفئة المستهدفة</div>
                </div>
                <div class="field-value">
                  {{ requestData.trainingProgram.targetCategory }}
                </div>
              </div>
            </div>
            <div class="row-data training-program-description">
              <div class="training-body__info">
                <div class="field-container">
                  <app-svg-icon
                    class="field-icon"
                    name="book-icon"
                  ></app-svg-icon>
                  <div class="field-name">وصف البرنامج التدريبي</div>
                </div>
                <div class="field-value">
                  {{ requestData.trainingProgram.description }}
                </div>
              </div>
            </div>
            <div *ngIf="isEditableApplication" class="accordions-container">
              <accordion
                *ngIf="
                  filterCriteria(
                    documentInspectorStateService
                      ?.trainingProgramDocumentMapping[0]?.programDocumentLookup
                      .programDocumentCriteriaMappings
                  )?.length
                "
                [customClass]="'criteria-accordion'"
                [header]="
                  'معايير بيانات البرنامج التدريبي ' +
                  (filterCriteria(
                    documentInspectorStateService
                      ?.trainingProgramDocumentMapping[0]?.programDocumentLookup
                      .programDocumentCriteriaMappings,
                    true
                  )?.length
                    ? '(المدقق الداخلي)'
                    : '')
                "
              >
                <div class="checked-section">
                  <app-view-evaluation
                    [showNoteUserName]="false"
                    [filterAccordionCriteria]="
                      filterCriteria(
                        documentInspectorStateService
                          ?.trainingProgramDocumentMapping[0]
                          ?.programDocumentLookup
                          .programDocumentCriteriaMappings
                      )
                    "
                  />
                </div>
              </accordion>
              <accordion
                *ngIf="
                  filterCriteria(
                    documentInspectorStateService
                      ?.trainingProgramDocumentMapping[0]?.programDocumentLookup
                      .programDocumentCriteriaMappings,
                    true
                  )?.length
                "
                [customClass]="
                  'technical-advisor-evaluation criteria-accordion'
                "
                [header]="'معايير بيانات البرنامج التدريبي (الخبير الفني)'"
              >
                <div class="checked-section">
                  <app-view-evaluation
                    [showNoteUserName]="false"
                    [filterAccordionCriteria]="
                      filterCriteria(
                        documentInspectorStateService
                          ?.trainingProgramDocumentMapping[0]
                          ?.programDocumentLookup
                          .programDocumentCriteriaMappings,
                        true
                      )
                    "
                  />
                </div>
              </accordion>
              <accordion
                [customClass]="'notes-accordion'"
                *ngIf="
                  documentInspectorStateService
                    ?.trainingProgramDocumentMapping[0]?.documentNotes?.length
                "
                [header]="'ملاحظات أخرى'"
              >
                <div class="user-notes">
                  <div
                    *ngFor="
                      let note of documentInspectorStateService
                        ?.trainingProgramDocumentMapping[0]?.documentNotes
                    "
                    class="user-note"
                    [class.empty]="!note.note"
                  >
                    <app-comment-section [data]="note" [showUserName]="false" />
                  </div>
                </div>
              </accordion>
            </div>
          </div>

          <ng-template #trainingProgramForm>
            <app-modal
              [modalContent]="modalContent"
              [showModal]="showTooltipModal"
              (closeModal)="closeModal()"
              [customClass]="'documents-modal'"
            >
              <div class="modal-text-body">
                <h2>تأكيد على اسم البرنامج</h2>
                <p class="popup-subtitle">
                  الرجاء التأكد من اسم البرنامج قبل تقديم الطلب، حيث سيستخدم هذا
                  الاسم في الشهادة ولن يكون بالإمكان تغييره.
                </p>
              </div>
            </app-modal>
            <span
              class="user-guide"
              *ngIf="isTrainingOrganization() && !edittingTrainingProgramTab"
              >الرجاء الاطلاع على الدليل الإرشادي قبل تعبئة الحقول (<a
                (click)="userGuideManual()"
              >
                الدليل الإرشادي</a
              >)</span
            >
            <div
              class="tab-content__container"
              [ngClass]="{
                'tab-data': id && edittingTrainingProgramTab && !isDraft,
              }"
            >
              <app-page-title
                title="بيانات البرنامج التدريبي"
                customClass="form-tab-title"
                *ngIf="id && !isDraft"
              />
              <div class="form-row">
                <div class="form-col">
                  <div class="tootltip-name-arabic">
                    <svg
                      class="information-icon"
                      (click)="infoIconClickHandler()"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <use [attr.xlink:href]="'#info-icon'"></use>
                    </svg>
                  </div>
                  <text-field
                    id="trainingProgramName"
                    [label]="'اسم البرنامج التدريبي' | translate"
                    [control]="accreditationForm?.get('trainingProgramName')"
                    [isRequired]="true"
                    [errorMessage]="
                      accreditationForm?.get('trainingProgramName').errors
                        ?.pattern
                        ? 'ادخل حروفاً بالعربية و الإنجليزية فقط'
                        : 'هذا الحقل المطلوب'
                    "
                  ></text-field>
                </div>
                <div class="form-col">
                  <div class="tootltip-name-english">
                    <svg
                      class="information-icon"
                      (click)="infoIconClickHandler()"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <use [attr.xlink:href]="'#info-icon'"></use>
                    </svg>
                  </div>
                  <text-field
                    id="trainingProgramEnglishName"
                    [label]="
                      'اسم البرنامج التدريبي باللغة الإنجليزية' | translate
                    "
                    [control]="
                      accreditationForm?.get('trainingProgramEnglishName')
                    "
                    [isRequired]="true"
                    [errorMessage]="
                      accreditationForm?.get('trainingProgramEnglishName')
                        .errors?.pattern
                        ? 'ادخل حروفاً بالعربية و الإنجليزية فقط'
                        : 'هذا الحقل المطلوب'
                    "
                  ></text-field>
                </div>
              </div>

              <div class="form-row">
                <div class="form-col">
                  <dropdown
                    [options]="trainingProgramType"
                    id="trainingProgramType"
                    [label]="'نوع البرنامج التدريبي' | translate"
                    [control]="accreditationForm?.get('trainingProgramType')"
                    [isRequired]="true"
                    [placeholder]="'الرجاء الاختيار من القائمة المنسدلة'"
                  >
                  </dropdown>
                </div>
                <div class="form-col">
                  <dropdown
                    [options]="trainingProgramLevels"
                    id="trainingProgramLevel"
                    [label]="'مستوى البرنامج التدريبي' | translate"
                    [control]="accreditationForm?.get('trainingProgramLevel')"
                    [isRequired]="true"
                    [placeholder]="'الرجاء الاختيار من القائمة المنسدلة'"
                  >
                  </dropdown>
                </div>
              </div>
              <div class="form-row">
                <div class="form-col">
                  <text-field
                    id="trainingProgramDays"
                    [label]="'مدة البرنامج التدريبي (بالأيام )' | translate"
                    [control]="accreditationForm?.get('trainingProgramDays')"
                    [keyFilter]="numberOnlyKeyFilter"
                    [isRequired]="true"
                  ></text-field>
                </div>
                <div class="form-col">
                  <text-field
                    id="trainingProgramHours"
                    [label]="'عدد الساعات التدريبية الإجمالية' | translate"
                    [control]="accreditationForm?.get('trainingProgramHours')"
                    [keyFilter]="numberOnlyKeyFilter"
                    [isRequired]="true"
                  ></text-field>
                </div>
              </div>
              <div class="form-row">
                <div class="form-col">
                  <text-field
                    id="trainingProgramPurpose"
                    [label]="'الهدف العام من البرنامج التدريبي' | translate"
                    [control]="accreditationForm?.get('trainingProgramPurpose')"
                    [isRequired]="true"
                  ></text-field>
                </div>
                <div class="form-col">
                  <dropdown
                    [options]="trainingProgramLanguages"
                    id="trainingProgramLanguage"
                    [label]="'لغة البرنامج التدريبي' | translate"
                    [control]="
                      accreditationForm?.get('trainingProgramLanguage')
                    "
                    [isRequired]="true"
                    [placeholder]="'الرجاء الاختيار من القائمة المنسدلة'"
                  >
                  </dropdown>
                </div>
              </div>
              <div class="form-row">
                <div class="form-col">
                  <text-field
                    id="targetedCategory"
                    [label]="'الفئة المستهدفة' | translate"
                    [control]="accreditationForm?.get('targetedCategory')"
                    [isRequired]="true"
                  ></text-field>
                </div>
              </div>
              <div class="form-row">
                <div class="form-col text-area">
                  <text-area
                    id="programDescription"
                    rows="5"
                    [label]="'وصف البرنامج التدريبي' | translate"
                    [control]="accreditationForm?.get('programDescription')"
                    [isRequired]="true"
                  ></text-area>
                </div>
              </div>
              <div *ngIf="isEditableApplication" class="tab-buttons-container">
                <app-custom-button
                  buttonText="حفظ"
                  buttonStyle="greenButton"
                  [disabled]="isTrainingProgramSaveButtonDisabled"
                  (click)="onUpdateTrainingProgramSection()"
                />
                <app-custom-button
                  buttonText="رجوع"
                  buttonStyle="transperantGreenButton"
                  (click)="resetTrainingProgramDataTab()"
                />
              </div>
              <div *ngIf="isEditableApplication" class="accordions-container">
                <accordion
                  *ngIf="
                    filterCriteria(
                      documentInspectorStateService
                        ?.trainingProgramDocumentMapping[0]
                        ?.programDocumentLookup.programDocumentCriteriaMappings
                    )?.length
                  "
                  [customClass]="'criteria-accordion'"
                  [header]="
                    'معايير بيانات البرنامج التدريبي ' +
                    (filterCriteria(
                      documentInspectorStateService
                        ?.trainingProgramDocumentMapping[0]
                        ?.programDocumentLookup.programDocumentCriteriaMappings,
                      true
                    )?.length
                      ? '(المدقق الداخلي)'
                      : '')
                  "
                >
                  <div class="checked-section">
                    <app-view-evaluation
                      [showNoteUserName]="false"
                      [filterAccordionCriteria]="
                        filterCriteria(
                          documentInspectorStateService
                            ?.trainingProgramDocumentMapping[0]
                            ?.programDocumentLookup
                            .programDocumentCriteriaMappings
                        )
                      "
                    />
                  </div>
                </accordion>
                <accordion
                  *ngIf="
                    filterCriteria(
                      documentInspectorStateService
                        ?.trainingProgramDocumentMapping[0]
                        ?.programDocumentLookup.programDocumentCriteriaMappings,
                      true
                    )?.length
                  "
                  [customClass]="
                    'technical-advisor-evaluation criteria-accordion'
                  "
                  [header]="'معايير بيانات البرنامج التدريبي (الخبير الفني)'"
                >
                  <div class="checked-section">
                    <app-view-evaluation
                      [showNoteUserName]="false"
                      [filterAccordionCriteria]="
                        filterCriteria(
                          documentInspectorStateService
                            ?.trainingProgramDocumentMapping[0]
                            ?.programDocumentLookup
                            .programDocumentCriteriaMappings,
                          true
                        )
                      "
                    />
                  </div>
                </accordion>
                <accordion
                  [customClass]="'notes-accordion'"
                  *ngIf="
                    documentInspectorStateService
                      ?.trainingProgramDocumentMapping[0]?.documentNotes?.length
                  "
                  [header]="'ملاحظات أخرى'"
                >
                  <div class="user-notes">
                    <div
                      *ngFor="
                        let note of documentInspectorStateService
                          ?.trainingProgramDocumentMapping[0]?.documentNotes
                      "
                      class="user-note"
                      [class.empty]="!note.note"
                    >
                      <app-comment-section
                        [data]="note"
                        [showUserName]="false"
                      />
                    </div>
                  </div>
                </accordion>
              </div>
            </div>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel header="مستندات البرنامج">
          <div
            class="tab-data"
            *ngIf="
              id && !edittingTrainingDocumentsTab && !isDraft;
              else trainingDocuments
            "
          >
            <div class="tab-title-container">
              <app-page-title title="مستندات البرنامج" />
              <img
                *ngIf="isEditableApplication"
                (click)="edittingTrainingDocumentsTab = true"
                class="edit-tab-icon"
                src="assets/images/edit-notes.svg"
              />
            </div>

            <div class="tab-content__container">
              <div
                class="file-uploads-container"
                *ngFor="
                  let documentObject of requiredDocuments;
                  let idx = index
                "
              >
                <file-upload
                  modifyRemovedFiles="{{ true }}"
                  [readonly]="true"
                  [id]="idx"
                  [documentLookup]="documentObject.programDocuments.key"
                  [title]="documentObject.programDocuments.value"
                  [subtitles]="documentObject.programDocumentRequirements"
                  [popupSubtitle]="
                    'سيتم تقييم هذا الملف بناء على المعايير التالية، يرجى الاطلاع عليها للتأكد من مطابقتها في الملف المرفق.'
                  "
                  [files]="
                    accreditationForm?.get(
                      'fileField_' + documentObject.programDocuments.key
                    )?.value
                  "
                  [descriptionLines]="
                    documentObject.programDocumentDescriptions
                  "
                  [control]="
                    accreditationForm?.get(
                      'fileField_' + documentObject.programDocuments.key
                    )
                  "
                  [onFileClick]="viewFile"
                  [fileIndex]="fileIndex"
                  [updateRequestNumber]="updateRequestNumber"
                >
                </file-upload>
                <div *ngIf="isEditableApplication" class="accordions-container">
                  <accordion
                    *ngIf="
                      filterCriteria(
                        documentInspectorStateService
                          ?.trainingProgramDocumentMapping[
                          documentObject.programDocuments.key - 1
                        ]?.programDocumentLookup.programDocumentCriteriaMappings
                      )?.length
                    "
                    [customClass]="'criteria-accordion'"
                    [header]="
                      'معايير ' +
                      documentInspectorStateService
                        ?.trainingProgramDocumentMapping[
                        documentObject.programDocuments.key - 1
                      ]?.programDocumentLookup.name +
                      (filterCriteria(
                        documentInspectorStateService
                          ?.trainingProgramDocumentMapping[
                          documentObject.programDocuments.key - 1
                        ]?.programDocumentLookup
                          .programDocumentCriteriaMappings,
                        true
                      )?.length
                        ? ' (المدقق الداخلي)'
                        : '')
                    "
                  >
                    <div class="checked-section">
                      <app-view-evaluation
                        [showNoteUserName]="false"
                        [filterAccordionCriteria]="
                          filterCriteria(
                            documentInspectorStateService
                              ?.trainingProgramDocumentMapping[
                              documentObject.programDocuments.key - 1
                            ]?.programDocumentLookup
                              ?.programDocumentCriteriaMappings
                          )
                        "
                      />
                    </div>
                  </accordion>
                  <accordion
                    *ngIf="
                      filterCriteria(
                        documentInspectorStateService
                          ?.trainingProgramDocumentMapping[
                          documentObject.programDocuments.key - 1
                        ]?.programDocumentLookup
                          .programDocumentCriteriaMappings,
                        true
                      )?.length
                    "
                    [customClass]="
                      'technical-advisor-evaluation criteria-accordion'
                    "
                    [header]="
                      'معايير ' +
                      documentInspectorStateService
                        ?.trainingProgramDocumentMapping[
                        documentObject.programDocuments.key - 1
                      ]?.programDocumentLookup.name +
                      ' (الخبير الفني)'
                    "
                  >
                    <div class="checked-section">
                      <app-view-evaluation
                        [showNoteUserName]="false"
                        [filterAccordionCriteria]="
                          filterCriteria(
                            documentInspectorStateService
                              ?.trainingProgramDocumentMapping[
                              documentObject.programDocuments.key - 1
                            ]?.programDocumentLookup
                              ?.programDocumentCriteriaMappings,
                            true
                          )
                        "
                      />
                    </div>
                  </accordion>
                  <accordion
                    [customClass]="'notes-accordion'"
                    *ngIf="
                      documentInspectorStateService
                        ?.trainingProgramDocumentMapping[
                        documentObject.programDocuments.key - 1
                      ]?.documentNotes?.length
                    "
                    [header]="'ملاحظات أخرى'"
                  >
                    <div class="user-notes">
                      <div
                        *ngFor="
                          let note of documentInspectorStateService
                            ?.trainingProgramDocumentMapping[
                            documentObject.programDocuments.key - 1
                          ]?.documentNotes
                        "
                        class="user-note"
                        [class.empty]="!note.note"
                      >
                        <app-comment-section
                          [data]="note"
                          [showUserName]="false"
                        />
                      </div>
                    </div>
                  </accordion>
                </div>
              </div>
            </div>
          </div>
          <ng-template #trainingDocuments>
            <span
              class="user-guide"
              *ngIf="isTrainingOrganization() && !edittingTrainingDocumentsTab"
              >الرجاء الاطلاع على الدليل الإرشادي قبل تعبئة الحقول (<a
                (click)="userGuideManual()"
              >
                الدليل الإرشادي</a
              >)</span
            >
            <div
              [ngClass]="{
                'tab-data': id && edittingTrainingDocumentsTab && !isDraft,
              }"
            >
              <div
                class="tab-title-container"
                *ngIf="id && edittingTrainingDocumentsTab && !isDraft"
              >
                <app-page-title title="مستندات البرنامج" />
              </div>
              <div class="tab-content__container">
                <div
                  class="file-uploads-container"
                  *ngFor="
                    let documentObject of requiredDocuments;
                    let idx = index
                  "
                >
                  <file-upload
                    modifyRemovedFiles="{{ true }}"
                    [readonly]="isReadOnly"
                    [canReplaceFile]="
                      documentObject.programDocuments.value ===
                        'المادة التدريبية' && isEditableApplication
                    "
                    [id]="idx"
                    [title]="documentObject.programDocuments.value"
                    [subtitles]="documentObject.programDocumentRequirements"
                    [popupSubtitle]="
                      'سيتم تقييم هذا الملف بناء على المعايير التالية، يرجى الاطلاع عليها للتأكد من مطابقتها في الملف المرفق.'
                    "
                    [files]="
                      accreditationForm?.get(
                        'fileField_' + documentObject.programDocuments.key
                      )?.value
                    "
                    [descriptionLines]="
                      documentObject.programDocumentDescriptions
                    "
                    [control]="
                      accreditationForm?.get(
                        'fileField_' + documentObject.programDocuments.key
                      )
                    "
                    (validationErrorEmitter)="
                      checkProgramDocumentsValidationError($event)
                    "
                    [updateRequestNumber]="updateRequestNumber"
                  >
                  </file-upload>
                  <div
                    *ngIf="isEditableApplication"
                    class="accordions-container"
                  >
                    <accordion
                      *ngIf="
                        filterCriteria(
                          documentInspectorStateService
                            ?.trainingProgramDocumentMapping[
                            documentObject.programDocuments.key - 1
                          ]?.programDocumentLookup
                            .programDocumentCriteriaMappings
                        )?.length
                      "
                      [customClass]="'criteria-accordion'"
                      [header]="
                        'معايير ' +
                        documentInspectorStateService
                          ?.trainingProgramDocumentMapping[
                          documentObject.programDocuments.key - 1
                        ]?.programDocumentLookup.name +
                        (filterCriteria(
                          documentInspectorStateService
                            ?.trainingProgramDocumentMapping[
                            documentObject.programDocuments.key - 1
                          ]?.programDocumentLookup
                            .programDocumentCriteriaMappings,
                          true
                        )?.length
                          ? ' (المدقق الداخلي)'
                          : '')
                      "
                    >
                      <div class="checked-section">
                        <app-view-evaluation
                          [showNoteUserName]="false"
                          [filterAccordionCriteria]="
                            filterCriteria(
                              documentInspectorStateService
                                ?.trainingProgramDocumentMapping[
                                documentObject.programDocuments.key - 1
                              ]?.programDocumentLookup
                                ?.programDocumentCriteriaMappings
                            )
                          "
                        />
                      </div>
                    </accordion>
                    <accordion
                      *ngIf="
                        filterCriteria(
                          documentInspectorStateService
                            ?.trainingProgramDocumentMapping[
                            documentObject.programDocuments.key - 1
                          ]?.programDocumentLookup
                            .programDocumentCriteriaMappings,
                          true
                        )?.length
                      "
                      [customClass]="
                        'technical-advisor-evaluation criteria-accordion'
                      "
                      [header]="
                        'معايير ' +
                        documentInspectorStateService
                          ?.trainingProgramDocumentMapping[
                          documentObject.programDocuments.key - 1
                        ]?.programDocumentLookup.name +
                        ' (الخبير الفني)'
                      "
                    >
                      <div class="checked-section">
                        <app-view-evaluation
                          [showNoteUserName]="false"
                          [filterAccordionCriteria]="
                            filterCriteria(
                              documentInspectorStateService
                                ?.trainingProgramDocumentMapping[
                                documentObject.programDocuments.key - 1
                              ]?.programDocumentLookup
                                ?.programDocumentCriteriaMappings,
                              true
                            )
                          "
                        />
                      </div>
                    </accordion>
                    <accordion
                      [customClass]="'notes-accordion'"
                      *ngIf="
                        documentInspectorStateService
                          ?.trainingProgramDocumentMapping[
                          documentObject.programDocuments.key - 1
                        ]?.documentNotes?.length
                      "
                      [header]="'ملاحظات أخرى'"
                    >
                      <div class="user-notes">
                        <div
                          *ngFor="
                            let note of documentInspectorStateService
                              ?.trainingProgramDocumentMapping[
                              documentObject.programDocuments.key - 1
                            ]?.documentNotes
                          "
                          class="user-note"
                          [class.empty]="!note.note"
                        >
                          <app-comment-section
                            [data]="note"
                            [showUserName]="false"
                          />
                        </div>
                      </div>
                    </accordion>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="isEditableApplication" class="tab-buttons-container">
              <app-custom-button
                buttonText="حفظ"
                buttonStyle="greenButton"
                (click)="onUpdateDocumentFilesSection()"
                [disabled]="isProgramDocumentsSaveButtonDisabled"
              />
              <app-custom-button
                buttonText="رجوع"
                buttonStyle="transperantGreenButton"
                (click)="resetTrainingDocumentsTab()"
              /></div
          ></ng-template>
          <app-document-inspector
            *ngIf="id"
            #inspectorComponent
            [isCheckbox]="false"
            [openModal]="isInspectorModalOpen"
            [fileIndex]="fileIndex"
            (closeModal)="hideModal()"
            [customClass]="'initial-document'"
            [requestStatus]="requestStatus"
            [allNotes]="allNotes"
            (showTabDataIndexValueChanged)="onTabDataIndexValueChange($event)"
            [updateRequestNumber]="updateRequestNumber"
            (showTrainingDataChange)="onShowTrainingDataChange($event)"
            (showPledgeChange)="onShowPledge($event)"
          >
            <div *ngIf="showTrainingData" class="training-program__data">
              <div class="training-body__title">بيانات البرنامج التدريبي</div>
              <div class="row-data">
                <div class="training-body__info">
                  <div class="field-container">
                    <app-svg-icon class="field-icon" name="document-icon" />
                    <div class="field-name">اسم البرنامج التدريبي</div>
                  </div>
                  <div class="field-value">
                    {{ requestData.trainingProgram.nameAr }}
                  </div>
                </div>
                <div class="training-body__info">
                  <div class="field-container">
                    <app-svg-icon
                      class="field-icon"
                      name="document-icon"
                    ></app-svg-icon>
                    <div class="field-name">
                      اسم البرنامج التدريبي باللغة الإنجليزية
                    </div>
                  </div>
                  <div class="field-value">
                    {{ requestData.trainingProgram.nameEn }}
                  </div>
                </div>
              </div>
              <div class="row-data">
                <div class="training-body__info">
                  <div class="field-container">
                    <app-svg-icon
                      class="field-icon"
                      name="note-icon"
                    ></app-svg-icon>
                    <div class="field-name">نوع البرنامج التدريبي</div>
                  </div>
                  <div class="field-value">
                    {{ requestData.trainingProgram.trainingProgramType }}
                  </div>
                </div>
                <div class="training-body__info">
                  <div class="field-container">
                    <app-svg-icon
                      class="field-icon"
                      name="badge-icon"
                    ></app-svg-icon>
                    <div class="field-name">مستوى البرنامج التدريبي</div>
                  </div>
                  <div class="field-value">
                    {{ requestData.trainingProgram.programLevelName }}
                  </div>
                </div>
              </div>
              <div class="row-data">
                <div class="training-body__info">
                  <div class="field-container">
                    <app-svg-icon
                      class="field-icon"
                      name="clock-icon"
                    ></app-svg-icon>
                    <div class="field-name">
                      مدة البرنامج التدريبي (بالأيام)
                    </div>
                  </div>
                  <div class="field-value">
                    {{ requestData.trainingProgram.trainingProgramDuration }}
                  </div>
                </div>
                <div class="training-body__info">
                  <div class="field-container">
                    <app-svg-icon
                      class="field-icon"
                      name="clock-icon"
                    ></app-svg-icon>
                    <div class="field-name">
                      عدد الساعات التدريبية الإجمالية
                    </div>
                  </div>
                  <div class="field-value">
                    {{ requestData.trainingProgram.totalTrainingHours }}
                  </div>
                </div>
              </div>
              <div class="row-data">
                <div class="training-body__info">
                  <div class="field-container">
                    <app-svg-icon class="field-icon" name="target-icon" />
                    <div class="field-name">
                      الهدف العام من البرنامج التدريبي
                    </div>
                  </div>
                  <div class="field-value">
                    {{ requestData.trainingProgram.programObjective }}
                  </div>
                </div>
                <div class="training-body__info">
                  <div class="field-container">
                    <app-svg-icon
                      class="field-icon"
                      name="globe-icon"
                    ></app-svg-icon>
                    <div class="field-name">لغة البرنامج التدريبي</div>
                  </div>
                  <div class="field-value">
                    {{ requestData.trainingProgram.languageLookupName }}
                  </div>
                </div>
              </div>
              <div class="row-data">
                <div class="training-body__info">
                  <div class="field-container">
                    <app-svg-icon class="field-icon" name="users-icon" />
                    <div class="field-name">الفئة المستهدفة</div>
                  </div>
                  <div class="field-value">
                    {{ requestData.trainingProgram.targetCategory }}
                  </div>
                </div>
              </div>
              <div>
                <div class="training-body__info">
                  <div class="field-container">
                    <app-svg-icon
                      class="field-icon"
                      name="book-icon"
                    ></app-svg-icon>
                    <div class="field-name">وصف البرنامج التدريبي</div>
                  </div>
                  <div class="field-value training-program-description">
                    {{ requestData.trainingProgram.description }}
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-content__container" *ngIf="showPledge">
              <div class="training-body__title">التعهد العام</div>

              <div class="pledge-container">
                <span class="pledge-intro"
                  >السادة المركز السعودي لكفاءة الطاقة حفظهم االله
                </span>
                <p class="pledge-intro">السلام عليكم ورحمة االله وبركاته</p>
                <p class="pledge-para">
                  نتعهد نحن
                  {{ requestData.trainingOrganization.organizationName }}،
                  بالالتزام بلائحة اعتماد البرامج التدريبية في مجال كفاءة الطاقة
                  والدليل الإرشادي للائحة وكافة التعاميم والتعليمات الخاصة التي
                  يصدرها المركز السعودي لكفاءة الطاقة بهذا الشأن.
                </p>
                <span class="pledge-para salute"
                  >وتقبلوا وافر التحية والتقدير</span
                >
                <div class="applicant-container">
                  <span class="applicant-name__text">اسم مقدم الطلب</span>
                  <span class="applicant-name">{{
                    requestData.generalCommitment.applicantsName
                  }}</span>
                </div>
                <div class="electronic-signature__container" *ngIf="imgUrl">
                  <span>التوقيع الإلكتروني</span>
                  <div class="electronic-signature">
                    <div class="signature-container">
                      <img
                        [src]="imgUrl"
                        alt="Electronic Signature"
                        class="signature-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </app-document-inspector>
        </p-tabPanel>

        <p-tabPanel header="التعهد العام">
          <div
            *ngIf="
              id && !edittingGeneralCommitmentTab && !isDraft;
              else generalCommitmentForm
            "
            class="general-commitment-tab-data"
          >
            <div class="tab-title-container">
              <app-page-title title="التعهد العام" />
              <img
                *ngIf="isEditableApplication"
                (click)="edittingGeneralCommitmentTab = true"
                class="edit-tab-icon"
                src="assets/images/edit-notes.svg"
              />
            </div>
            <div class="pledge-intro-container">
              <span class="pledge-intro"
                >السادة المركز السعودي لكفاءة الطاقة حفظهم االله
              </span>
              <span class="pledge-intro">
                السلام عليكم ورحمة االله وبركاته</span
              >
            </div>
            <p class="pledge-para">
              نتعهد نحن {{ requestData.trainingOrganization.organizationName }}،
              بالالتزام بلائحة اعتماد البرامج التدريبية في مجال كفاءة الطاقة
              والدليل الإرشادي للائحة وكافة التعاميم والتعليمات الخاصة التي
              يصدرها المركز السعودي لكفاءة الطاقة بهذا الشأن.
            </p>
            <span class="pledge-para">وتقبلوا وافر التحية والتقدير</span>
            <div class="applicant-container">
              <span class="applicant-name__text">اسم مقدم الطلب</span>
              <span class="applicant-name">{{
                requestData.generalCommitment.applicantsName
              }}</span>
            </div>
            <div
              *ngIf="
                isEditableApplication &&
                (filterCriteria(
                  documentInspectorStateService?.trainingProgramDocumentMapping[
                    documentInspectorStateService
                      ?.trainingProgramDocumentMapping?.length - 1
                  ]?.programDocumentLookup.programDocumentCriteriaMappings
                )?.length ||
                  documentInspectorStateService?.trainingProgramDocumentMapping[
                    documentInspectorStateService
                      ?.trainingProgramDocumentMapping?.length - 1
                  ]?.documentNotes?.length)
              "
              class="accordions-container"
            >
              <accordion
                *ngIf="
                  filterCriteria(
                    documentInspectorStateService
                      ?.trainingProgramDocumentMapping[
                      documentInspectorStateService
                        ?.trainingProgramDocumentMapping?.length - 1
                    ]?.programDocumentLookup.programDocumentCriteriaMappings
                  )?.length
                "
                [customClass]="'criteria-accordion'"
                [header]="'معايير التعهد العام'"
              >
                <div class="checked-section">
                  <app-view-evaluation
                    [showNoteUserName]="false"
                    [filterAccordionCriteria]="
                      filterCriteria(
                        documentInspectorStateService
                          ?.trainingProgramDocumentMapping[
                          documentInspectorStateService
                            ?.trainingProgramDocumentMapping?.length - 1
                        ]?.programDocumentLookup.programDocumentCriteriaMappings
                      )
                    "
                  />
                </div>
              </accordion>
              <accordion
                [customClass]="'notes-accordion'"
                *ngIf="
                  documentInspectorStateService?.trainingProgramDocumentMapping[
                    documentInspectorStateService
                      ?.trainingProgramDocumentMapping?.length - 1
                  ]?.documentNotes?.length
                "
                [header]="'ملاحظات أخرى'"
              >
                <div class="user-notes">
                  <div
                    *ngFor="
                      let note of documentInspectorStateService
                        ?.trainingProgramDocumentMapping[
                        documentInspectorStateService
                          ?.trainingProgramDocumentMapping?.length - 1
                      ]?.documentNotes
                    "
                    class="user-note"
                    [class.empty]="!note.note"
                  >
                    <app-comment-section [data]="note" [showUserName]="false" />
                  </div>
                </div>
              </accordion>
            </div>
          </div>
          <ng-template #generalCommitmentForm>
            <span
              class="user-guide"
              *ngIf="isTrainingOrganization() && !edittingGeneralCommitmentTab"
              >الرجاء الاطلاع على الدليل الإرشادي قبل تعبئة الحقول (<a
                (click)="userGuideManual()"
              >
                الدليل الإرشادي</a
              >)</span
            >
            <div
              class="tab-content__container"
              [ngClass]="{
                'tab-data': id && edittingGeneralCommitmentTab && !isDraft,
              }"
            >
              <app-page-title
                title="التعهد العام"
                customClass="form-tab-title"
                *ngIf="id && !isDraft"
              />
              <div class="oath-container">
                <div class="pledge-intro-container">
                  <span class="pledge-intro"
                    >السادة المركز السعودي لكفاءة الطاقة حفظهم االله
                  </span>
                  <span class="pledge-intro">
                    السلام عليكم ورحمة االله وبركاته</span
                  >
                </div>
                <p class="pledge-para">
                  نتعهد نحن
                  {{ accreditationForm?.get('agencyName')?.value }}، بالالتزام
                  بلائحة اعتماد البرامج التدريبية في مجال كفاءة الطاقة والدليل
                  الإرشادي للائحة وكافة التعاميم والتعليمات الخاصة التي يصدرها
                  المركز السعودي لكفاءة الطاقة بهذا الشأن.
                </p>
                <span class="pledge-para">وتقبلوا وافر التحية والتقدير</span>
                <div class="form-col">
                  <text-field
                    id="applicantName"
                    [label]="'اسم مقدم الطلب' | translate"
                    [control]="accreditationForm?.get('applicantName')"
                    [isRequired]="true"
                  ></text-field>
                </div>
                <div>
                  <div class="electronic-signature">
                    <div class="checkbox-container">
                      <check-box
                        id="pledgeCheckbox"
                        [control]="accreditationForm?.get('pledge')"
                      ></check-box>
                      <span>
                        أؤكد بأنني قرأت وفهمت ووافقت على
                        <a routerLink="/privacy-policy" target="_blank">
                          سياسات الخصوصية
                        </a>
                        و
                        <a routerLink="/usage-policy" target="_blank">
                          الاستخدام
                        </a>
                        و
                        <a (click)="userGuideManual()"> الدليل الارشادي </a
                        >.</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="isEditableApplication"
                  class="tab-buttons-container"
                >
                  <app-custom-button
                    buttonText="حفظ"
                    buttonStyle="greenButton"
                    [disabled]="isGeneralCommitmentSaveButtonDisabled"
                    (click)="onUpdateGeneralCommitmentSection()"
                  />
                  <app-custom-button
                    buttonText="رجوع"
                    buttonStyle="transperantGreenButton"
                    (click)="resetGeneralCommitmentTab()"
                  />
                </div>
              </div>
              <div
                *ngIf="
                  isEditableApplication &&
                  (filterCriteria(
                    documentInspectorStateService
                      ?.trainingProgramDocumentMapping[
                      documentInspectorStateService
                        ?.trainingProgramDocumentMapping?.length - 1
                    ]?.programDocumentLookup.programDocumentCriteriaMappings
                  )?.length ||
                    documentInspectorStateService
                      ?.trainingProgramDocumentMapping[
                      documentInspectorStateService
                        ?.trainingProgramDocumentMapping?.length - 1
                    ]?.documentNotes?.length)
                "
                class="accordions-container"
              >
                <accordion
                  *ngIf="
                    filterCriteria(
                      documentInspectorStateService
                        ?.trainingProgramDocumentMapping[
                        documentInspectorStateService
                          ?.trainingProgramDocumentMapping?.length - 1
                      ]?.programDocumentLookup.programDocumentCriteriaMappings
                    )?.length
                  "
                  [customClass]="'criteria-accordion'"
                  [header]="'معايير التعهد العام'"
                >
                  <div class="checked-section">
                    <app-view-evaluation
                      [showNoteUserName]="false"
                      [filterAccordionCriteria]="
                        filterCriteria(
                          documentInspectorStateService
                            ?.trainingProgramDocumentMapping[
                            documentInspectorStateService
                              ?.trainingProgramDocumentMapping?.length - 1
                          ]?.programDocumentLookup
                            .programDocumentCriteriaMappings
                        )
                      "
                    />
                  </div>
                </accordion>
                <accordion
                  [customClass]="'notes-accordion'"
                  *ngIf="
                    documentInspectorStateService
                      ?.trainingProgramDocumentMapping[
                      documentInspectorStateService
                        ?.trainingProgramDocumentMapping?.length - 1
                    ]?.documentNotes?.length
                  "
                  [header]="'ملاحظات أخرى'"
                >
                  <div class="user-notes">
                    <div
                      *ngFor="
                        let note of documentInspectorStateService
                          ?.trainingProgramDocumentMapping[
                          documentInspectorStateService
                            ?.trainingProgramDocumentMapping?.length - 1
                        ]?.documentNotes
                      "
                      class="user-note"
                      [class.empty]="!note.note"
                    >
                      <app-comment-section
                        [data]="note"
                        [showUserName]="false"
                      />
                    </div>
                  </div>
                </accordion>
              </div>
            </div>
          </ng-template>
        </p-tabPanel>
        <p-tabPanel *ngIf="fieldVisitData" header="تقييم الزيارة الميدانية"
          ><div class="field-visit-tab tab-data">
            <app-field-visit-wrapper
              [fieldVisitData]="fieldVisitData"
              [requestStatus]="requestStatus"
              [accreditationForm]="accreditationForm"
            />
            <div
              class="checkbox-container"
              *ngIf="accreditationForm.get('fieldVisitRequiredChangesDone')"
            >
              <check-box
                [control]="
                  accreditationForm.get('fieldVisitRequiredChangesDone')
                "
              />
              <span>تم القيام بالاجراءات اللازمة</span>
            </div>
          </div></p-tabPanel
        >

        <p-tabPanel header="تفاصيل الدفع" *ngIf="showPaymentData">
          <app-payment-details
            [data]="requestData"
            (isSendInvoiceButtonDisabledEmitter)="
              isSendInvoiceDisabled = $event
            "
            #paymentComponent
          />
        </p-tabPanel>

        <p-tabPanel
          *ngIf="certificateData"
          header="شهادة الاعتماد"
          [disabled]="!isCertificateDownloadable"
        >
          <certificate
            [certificateData]="certificateData"
            *ngIf="isCertificateDownloadable"
          ></certificate>
        </p-tabPanel>
      </p-tabView>
    </div>
    <div class="buttons-container">
      <app-navigation-buttons
        [tabs]="tabs"
        [activeTabIndex]="activeTabIndex"
        (nextTab)="navigateToNextTab()"
        (previousTab)="navigateToPreviousTab()"
      ></app-navigation-buttons>
      <app-custom-button
        *ngIf="
          activeTabIndex === tabs.length - 1 &&
          (!isReadOnly || showSendInvoiceButton)
        "
        [buttonText]="submitButtonTextHandler()"
        [buttonStyle]="'greenButton'"
        [disabled]="
          accreditationForm?.invalid ||
          licenseDateError ||
          licenseExpiryDateError ||
          (showSendInvoiceButton && isSendInvoiceDisabled)
        "
        (click)="submitButtonClickHandler()"
      />
      <div class="success-modal__container">
        <app-modal
          [modalContent]="'submission'"
          [showModal]="showModal"
          (closeModal)="closeModel()"
          [customClass]="'success-modal'"
          [isCloseVisible]="submittedSuccessfully"
        >
          <div *ngIf="!submittedSuccessfully" class="submit-loading-spinner">
            <div class="submit-spinner"></div>
            {{ savingAsDraft ? 'جاري حفظ الطلب...' : 'جاري إرسال الطلب...' }}
          </div>
          <div *ngIf="submittedSuccessfully" class="modal-body">
            <app-svg-icon
              class="success-icon"
              name="success-icon"
            ></app-svg-icon>
            <span class="modal-text">{{
              savingAsDraft
                ? 'تم حفظ الطلب كمسودة'
                : isRenewalApplication
                  ? 'تم إرسال طلب التجديد بنجاح'
                  : 'تم إرسال الطلب بنجاح'
            }}</span>
          </div>
        </app-modal>
        <app-dialog
          [isCustomDialog]="true"
          [visible]="isDialogOpen"
          (onHide)="isDialogOpen = false"
          [width]="'430px'"
        >
          <div class="modal-body dialog">
            <span class="dialog-text"
              >هل أنت متأكد من القيام بالاجراءات،<br />
              وترغب بإرسال الطلب؟</span
            >
            <div class="buttons-container dialog-buttons">
              <app-custom-button
                [buttonText]="'متأكد'"
                [buttonStyle]="'greenButton'"
                (click)="onSubmit({ asDraft: false })"
              ></app-custom-button
              ><app-custom-button
                [buttonText]="'إلغاء الأمر'"
                [buttonStyle]="'transperantGreenButton'"
                (click)="isDialogOpen = false"
              ></app-custom-button>
            </div>
          </div>
        </app-dialog>
      </div>
      <app-custom-button
        *ngIf="!isReadOnly && !isEditableApplication"
        [buttonText]="
          getCurrentRoute() === '/update-application' ? 'تعديل' : 'حفظ كمسودة'
        "
        [buttonStyle]="'transperantGreenButton'"
        (click)="isReadOnly ? readOnly() : onSubmit({ asDraft: true })"
        [disabled]="
          accreditationForm?.get('agencyName').invalid ||
          accreditationForm?.get('address').errors?.pattern ||
          accreditationForm?.get('trainingProgramName').invalid ||
          accreditationForm?.get('trainingProgramEnglishName').errors
            ?.pattern ||
          accreditationForm?.get('email')?.errors?.pattern
        "
      ></app-custom-button>
    </div>
  </form>
</div>
<article class="tab-data rejection-tab" *ngIf="showRejectionReason">
  <app-page-title customClass="rejection-title"
    >اسباب رفض الطلب رقم
    <span class="green-color">{{
      requestData.trainingOrganization.reportId
    }}</span></app-page-title
  >
  <section class="rejection-reason" [innerHTML]="rejectionReasonNote"></section>
  <app-custom-button
    buttonText="العودة إلى تفاصيل الطلب"
    buttonStyle="greenButton"
    (click)="hideRejectionLayout()"
  />
</article>
