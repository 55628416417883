import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.development';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  initializeGTM(isAuthenticated: boolean) {
    const gtmIdForUser = environment.googleTagManagerIdForUsers;
    const gtmIdForVisitors = environment.googleTagManagerIdForVisitors;
    const googleDomain = environment.googleDomain;

    // Choose the correct GTM container based on authentication status
    const chosenGtmId = isAuthenticated ? gtmIdForUser : gtmIdForVisitors;

    // Initialize dataLayer if not already present
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    // Remove any existing GTM script and noscript if previously injected
    this.removeExistingGtmTags();

    // Create the GTM script tag
    const script = document.createElement('script');
    script.innerHTML = `
      (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = '${googleDomain}/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', '${chosenGtmId}');
    `;
    document.head.appendChild(script);

    // Create the GTM noscript tag
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `<iframe src="${googleDomain}/ns.html?id=${chosenGtmId}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(noscript);
  }

  private removeExistingGtmTags() {
    // Remove any previously injected GTM script tags
    const existingScripts = document.querySelectorAll('script');
    existingScripts.forEach((tag) => {
      if (tag.innerHTML.includes('gtm.js')) {
        tag.remove();
      }
    });

    // Remove any previously injected GTM noscript tags
    const existingNoScripts = document.querySelectorAll('noscript');
    existingNoScripts.forEach((tag) => {
      if (tag.innerHTML.includes('ns.html')) {
        tag.remove();
      }
    });
  }
}
