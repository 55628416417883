import { Component, OnInit } from '@angular/core';
import { ChartDataService } from '@app/dashboard/services/chart-data.service';
import {
  TableService,
  TableData,
} from '@app/dashboard/services/table-data.service';
import { Assets } from '@assets/images';
import { ReportCategory } from 'src/app/types/chart-reports';

@Component({
  selector: 'app-overall-reports',
  templateUrl: './overall-reports.component.html',
  styleUrl: './overall-reports.component.scss',
})
export class OverallReportsComponent implements OnInit {
  banner = Assets.banner;
  activeTabIndex: number = 0;
  chartType = 'bar';
  reportCategory = ReportCategory;

  years: { [key in ReportCategory]: number } = {
    [ReportCategory.AnnualReport]: new Date().getFullYear(),
    [ReportCategory.SemiAnnualReport]: new Date().getFullYear(),
    [ReportCategory.QuarterReport]: new Date().getFullYear(),
  };

  reportLabels: { [key in ReportCategory]: string } = {
    [ReportCategory.AnnualReport]:
      'عدد الطلبات السنوي للبرامج التدريبية التي قامت الجهات التدريبية بتأليفها',
    [ReportCategory.SemiAnnualReport]:
      'عدد الاعتمادات النصفي للبرامج التدريبية التي قامت الجهات التدريبية بتأليفها',
    [ReportCategory.QuarterReport]:
      'عدد الاعتمادات الربعي للبرامج التدريبية التي قامت الجهات التدريبية بتأليفها',
  };

  //reports Data values
  annualReport: any = null;
  semiAnnualReport: any = null;
  quarterReport: any = null;

  //table data values
  numberOfRequestsTable: TableData | null = null;
  averageTimeTable: TableData | null = null;

  constructor(
    private chartDataService: ChartDataService,
    private tableService: TableService,
  ) {}

  ngOnInit(): void {
    this.loadData(this.mapAccreditationRequestType(this.activeTabIndex));
  }

  onTabChange(event: any): void {
    this.activeTabIndex = event.index;
    this.loadData(this.mapAccreditationRequestType(this.activeTabIndex));
  }

  mapAccreditationRequestType(tabIndex: number): number {
    return tabIndex === 0 ? 2 : 1;
  }

  loadData(accreditationRequestType: number): void {
    this.clearChartData();

    // this.loadReport(ReportCategory.AnnualReport);
    // this.loadReport(ReportCategory.SemiAnnualReport);
    //  this.loadReport(ReportCategory.QuarterReport);

    this.tableService
      .getNumberOfRequestsData(accreditationRequestType)
      .subscribe((data) => {
        this.numberOfRequestsTable = data;
      });

    // Load table data

    if (this.activeTabIndex === 0) {
      this.tableService
        .getAverageTimeDataByOrg(accreditationRequestType)
        .subscribe((data) => {
          this.averageTimeTable = data;
        });
    } else if (this.activeTabIndex === 1) {
      this.tableService
        .getAverageTimeDataByCenter(accreditationRequestType)
        .subscribe((data) => {
          this.averageTimeTable = data;
        });
    }
  }

  loadReport(chart: ReportCategory): void {
    const year = this.years[chart];
    const accreditationRequestType = this.mapAccreditationRequestType(
      this.activeTabIndex,
    );

    this.chartDataService
      .getChartData(year, chart, accreditationRequestType)
      .subscribe(
        (data) => {
          switch (chart) {
            case ReportCategory.AnnualReport:
              this.annualReport = data.chart;
              break;
            case ReportCategory.SemiAnnualReport:
              this.semiAnnualReport = data.chart;
              break;
            case ReportCategory.QuarterReport:
              this.quarterReport = data.chart;
              break;
            default:
              console.error('Unknown report category');
          }
        },
        (error) => {
          console.error(`Error loading ${ReportCategory[chart]} data:`, error);
        },
      );
  }

  clearChartData(): void {
    this.annualReport = null;
    this.semiAnnualReport = null;
    this.quarterReport = null;
  }

  nextYear(chart: ReportCategory): void {
    this.changeYear(chart, 1);
  }

  previousYear(chart: ReportCategory): void {
    this.changeYear(chart, -1);
  }

  changeYear(chart: ReportCategory, increment: number): void {
    const newYear = this.years[chart] + increment;
    const currentYear = new Date().getFullYear();

    // Prevent future years
    if (newYear > currentYear) {
      return;
    }
    this.years[chart] = newYear;
    this.loadReport(chart);
  }
}
