import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { UIChart } from 'primeng/chart';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { jsPDF } from 'jspdf';
import { callAddFont } from '@assets/fonts/arabicLangForPDFExport/Vazirmatn-VariableFont_wght-normal';

@Component({
  selector: 'app-chart',
  templateUrl: './chart-report.component.html',
  styleUrls: ['./chart-report.component.scss'],
})
export class ChartReportsComponent implements OnInit, OnChanges {
  @Input() chartData: any; // Chart data to be received from parent
  @Input() chartTitle: string = '';
  @Input() currentYear: number = new Date().getFullYear();
  @Input() chartType: string = '';
  @Input() category!: string;
  @Input() showExportBtn: boolean = true;

  @Output() previousYear = new EventEmitter<void>();
  @Output() nextYear = new EventEmitter<void>();

  @ViewChild('chartComponent') chartComponent: UIChart;

  chartOptions: any; // Define chartOptions
  private chartInstance: any; // Declare chartInstance property

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.initializeChartOptions(); // Initialize chart options
    this.initializeChart(); // Initialize the chart on component load
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['chartData'] && !changes['chartData'].isFirstChange()) {
      this.updateChart(); // Update the chart when chartData changes
    }
  }

  initializeChartOptions() {
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          beginAtZero: true,
          reverse: true, // Reverse the scale for RTL
        },
        y: {
          beginAtZero: true,
        },
      },
      datasets: {
        bar: {
          barThickness: 'flex',
          maxBarThickness: 60,
        },
      },
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          align: 'start',
          labels: {
            padding: 10,
            boxWidth: 20,
            boxHeight: 20,
            font: {
              size: 12,
            },
            usePointStyle: true,
            maxWidth: 1, // Adjust this value as needed
            textAlign: 'right',
          },
        },
        datalabels: {
          display: true,
          color: '#ffffff', // Set the text color
          align: 'center', // Aligns the text at the center of the bar .
          anchor: 'center', // Anchors the text within the bar
          textAlign: 'center',
          font: {
            size: 10,
          },
        },
        tooltip: {
          enabled: true,
          yAlign: 'bottom',
          xAlign: 'center',
          displayColors: false, // Remove the color box
          callbacks: {
            title: function () {
              return ''; // Remove the title (months)
            },
            label: function (context) {
              // Return only the label, without the numerical value
              return context.dataset.label || context.label || '';
            },
          },
        },
      },
    };

    // Conditionally add scales and datasets if chartType is not 'pie'
    if (this.chartType !== 'pie') {
      this.chartOptions.scales = {
        x: {
          ticks: {
            display: true,
          },
          beginAtZero: true,
          reverse: true, // Reverse the scale for RTL
        },
      };

      this.chartOptions.datasets = {
        bar: {
          barThickness: 'flex',
          maxBarThickness: 60,
        },
      };
    }

    Chart.register(ChartDataLabels);
  }

  initializeChart() {
    const ctx = document.getElementById('chart') as HTMLCanvasElement;

    // Destroy any existing chart instance to avoid conflicts
    if (this.chartInstance) {
      this.chartInstance.destroy();
    }

    // Ensure chart canvas exists
    if (ctx) {
      this.chartInstance = new Chart(ctx, {
        type: 'bar',
        data: this.chartData,
        options: this.chartOptions,
      });
    }
  }

  updateChart() {
    if (this.chartInstance) {
      setTimeout(() => {
        // Update the chart data only if chartInstance is valid
        this.chartInstance.data = this.chartData;
        this.chartInstance.update();
      }, 0); // Set a small delay (0 ms) to allow the DOM to be fully ready
    }
  }

  downloadChart() {
    const chartElement = this.chartComponent.chart.canvas as HTMLCanvasElement;
    const imgData = chartElement.toDataURL('image/png'); // Convert chart to image
    jsPDF.API.events.push(['addFonts', callAddFont]);

    const pdf = new jsPDF({
      orientation: 'landscape', // Adjust orientation if needed
      unit: 'pt', // Define unit as points
      format: 'a4', // Or use 'letter' for different paper size
    });
    pdf.setFont('Vazirmatn-VariableFont_wght');
    pdf.setFontSize(16);
    // pdf.text(this.chartTitle, 330, 80); // Positioning (x:10, y:10)

    const titleWithYear = `${this.chartTitle} ${this.currentYear}`;

    // Positioning (x: 330, y: 80) - adjust as necessary
    pdf.text(titleWithYear, 450, 80, { align: 'center' });
    // Embed the chart image into the PDF
    pdf.addImage(
      imgData,
      'PNG',
      60,
      120,
      chartElement.width / 2,
      chartElement.height / 2,
    );

    // Save the generated PDF
    pdf.save('chart.pdf');
  }

  onPreviousYear() {
    this.previousYear.emit();
  }

  onNextYear() {
    this.nextYear.emit();
  }
}
