import { Component, Input } from '@angular/core';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';

import { callAddFont } from '@assets/fonts/arabicLangForPDFExport/Vazirmatn-VariableFont_wght-normal';

interface DataItem {
  id: number;
  name: string;
  age: number;
  email: string;
}

interface HeaderColumn {
  field: string;
  header: string;
}

@Component({
  selector: 'app-reports-table',
  templateUrl: './reports-table.component.html',
  styleUrls: ['./reports-table.component.scss'],
})
export class ReportsTableComponent {
  @Input() data: DataItem[] = [];
  @Input() title: string = 'Data Table';
  @Input() headers: HeaderColumn[] = [];
  @Input() category!: string;
  @Input() showExportBtn: boolean = true;

  exportPdf() {
    const doc = new jsPDF('p', 'pt', 'a4');

    // Register the font
    jsPDF.API.events.push(['addFonts', callAddFont]);

    // Set the font
    doc.setFont('Vazirmatn-VariableFont_wght');

    // Map headers to the expected format for autoTable
    const columns = this.headers.map((header) => ({
      title: header.header,
      dataKey: header.field,
    }));

    // Generate the table with RTL support
    autoTable(doc, {
      head: [columns],
      body: this.data.map((item) => columns.map((col) => item[col.dataKey])),
      styles: {
        font: 'Vazirmatn-VariableFont_wght',
        halign: 'center', // Right-align content
      },
      columnStyles: {
        0: { halign: 'center' }, // Ensure the first column is also right-aligned
      },
    });

    // Save the PDF
    doc.save('data.pdf');
  }
}
