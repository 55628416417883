import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScrollToTopService } from '@app/dashboard/services/scroll-to-top.service';
import { AuthService } from '@app/services/auth.service';
import { UserRole } from '@app/types/roles';
import { Assets } from '@assets/images';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  banner = Assets.banner;
  trainingRequest = Assets.trainingRequest;
  trainingApproval = Assets.trainingApproval;
  divider = Assets.dividerHomePage;

  constructor(
    private router: Router,
    private authService: AuthService,
    private scrollService: ScrollToTopService,
  ) {}

  ngOnInit(): void {
    this.scrollService.scrollToTop();
  }

  trainingObjectives = [
    ' رفع مستوى جودة البرامج التدريبية في مجال كفاءة الطاقة.',
    'زيادة الوعي بكفاءة الطاقة.',
    'بناء منظومة تدريب في مجال كفاءة الطاقة.',
    'إشراك القطاع الخاص في التدريب في مجال كفاءة الطاقة.',
  ];

  stages = [
    {
      title: 'تقديم الطلب',
      description: 'التأكد من اكتمال الملفات وصحتها',
      imgSrc: Assets.journey1,
    },
    {
      title: 'المراجعة الفنية',
      description: 'تقييم ملفات المادة التدريبية',
      imgSrc: Assets.journey2,
    },
    {
      title: 'الزيارة الميدانية',
      description: 'التأكد من توافر الأدوات والمعدات اللازمة للتدريب',
      imgSrc: Assets.journey3,
    },
    {
      title: 'تصويت أعضاء اللجنة',
      description: 'التصويت على الطلب',
      imgSrc: Assets.journey4,
    },
    {
      title: 'دفع المقابل المالي',
      description: 'دفع المقابل المالي والحصول على الفاتورة',
      imgSrc: Assets.journey5,
    },
    {
      title: 'إصدار الشهادة',
      description: 'إصدار شهادة الاعتماد',
      imgSrc: Assets.journey6,
    },
  ];

  handleClick(): void {
    if (
      this.authService.hasRole(UserRole.TrainingOrganization) ||
      !this.authService.isUserAuthenticated()
    ) {
      this.router.navigate(['/dashboard/accreditation-form'], {
        queryParams: { page: 'home', type: 'trainerProgram' },
      });
    }
  }
}
