<div class="programs-container">
  <div class="menu">
    <p-tabView>
      <loading-spinner />
      <p-tabPanel
        header="البرامج التدريبية في مجال كفاءة الطاقة التي عمل المركز على إعدادها"
      >
        <div *ngIf="!(isLoading | async)" class="progress-indicator">
          <p class="no-program-paragraph">لا يوجد طلبات</p>
        </div>
      </p-tabPanel>
      <p-tabPanel
        header="البرامج التدريبية في مجال كفاءة الطاقة التي قامت الجهات التدريبية بتأليفها"
      >
        <div *ngIf="!(isLoading | async)" class="tab-data__container">
          <div class="cards-container">
            <app-application-cards
              title="طلبات الاعتماد المكتملة"
              number="{{ informationPanel?.totaltrainingOrganizationDone }}"
            ></app-application-cards>
            <app-application-cards
              title="طلبات الاعتماد قيد الإنتظار"
              number="{{ informationPanel?.totaltrainingOrganizationNotDone }}"
            ></app-application-cards>
            <app-application-cards
              title="أضف برنامجًا جديدًا إلى مسارك"
              link="تقديم طلب اعتماد جديد"
              progType="trainerProgram"
            ></app-application-cards>
          </div>
          <div>
            <div
              *ngIf="
                !tableData?.data.length && !(isLoading | async);
                else tableBlock
              "
              class="progress-indicator"
            >
              <p class="no-program-paragraph">لا يوجد طلبات</p>
            </div>
            <ng-template #tableBlock>
              <app-table
                *ngIf="tableData?.data"
                [customClass]="'my-applications__table'"
                [headers]="headers"
                [data]="tableData?.data"
                [options]="status"
                [totalRecords]="tableData?.totalRecords"
                [isDateFilter]="true"
              ></app-table>
            </ng-template>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
