<div class="banner-container">
  <div class="welcome-text__container">
    <span class="welcome">مرحبا بك في </span>
    <h1 class="welcome-text">لوحة التحكم الرئيسية</h1>
  </div>
  <img [src]="banner" alt="banner" class="banner-image" />
</div>

<div class="container">
  <div>
    <p-tabView [activeIndex]="activeTabIndex" (onChange)="onTabChange($event)">
      <p-tabPanel header="الطلبات للبرامج التي قامت الجهات التدريبية بتأليفها">
        <div class="subject">
          <span>قياس المؤشرات</span>

          <!-- Active Users Report Chart -->
          <div>
            <app-chart
              [chartType]="chartTypeActiveUsers"
              [chartData]="activeUsersReport"
              [currentYear]="years[ChartType.ActiveUsers]"
              [chartTitle]="reportLabels[ChartType.ActiveUsers]"
              (previousYear)="previousYear(ChartType.ActiveUsers)"
              (nextYear)="nextYear(ChartType.ActiveUsers)"
            ></app-chart>
          </div>

          <!-- Application Completion Rate Chart -->
          <div>
            <app-chart
              [chartType]="chartTypeCompletionRate"
              [chartData]="applicationCompletionRate"
              [currentYear]="years[ChartType.ApplicationCompletion]"
              [chartTitle]="reportLabels[ChartType.ApplicationCompletion]"
              (previousYear)="previousYear(ChartType.ApplicationCompletion)"
              (nextYear)="nextYear(ChartType.ApplicationCompletion)"
            ></app-chart>
          </div>

          <div class="table-data">
            <div *ngIf="durationOfAccredition">
              <app-reports-table
                [data]="durationOfAccredition.data"
                [title]="durationOfAccredition.title"
                [headers]="durationOfAccredition.headers"
              ></app-reports-table>
            </div>

            <div *ngIf="durationOfSubmission">
              <app-reports-table
                [data]="durationOfSubmission.data"
                [title]="durationOfSubmission.title"
                [headers]="durationOfSubmission.headers"
              ></app-reports-table>
            </div>

            <div *ngIf="numberOfRequestsTable">
              <app-reports-table
                [data]="numberOfRequestsTable.data"
                [title]="numberOfRequestsTable.title"
                [headers]="numberOfRequestsTable.headers"
              ></app-reports-table>
            </div>
          </div>

          <!-- Withdrawn Application Rate Chart -->
          <app-chart
            [chartType]="chartTypeWithdrawalRate"
            [chartData]="withdrawnApplicationRate"
            [currentYear]="years[ChartType.WithdrawnApplications]"
            [chartTitle]="reportLabels[ChartType.WithdrawnApplications]"
            (previousYear)="previousYear(ChartType.WithdrawnApplications)"
            (nextYear)="nextYear(ChartType.WithdrawnApplications)"
          ></app-chart>
        </div>
      </p-tabPanel>

      <p-tabPanel header="الطلبات للبرامج التي عمل المركز على إعدادها">
        <div class="subject">
          <span>قياس المؤشرات</span>

          <!-- Active Users Report Chart -->
          <app-chart
            [chartType]="chartTypeActiveUsers"
            [chartData]="activeUsersReport"
            [currentYear]="years[ChartType.ActiveUsers]"
            [chartTitle]="reportLabels[ChartType.ActiveUsers]"
            (previousYear)="previousYear(ChartType.ActiveUsers)"
            (nextYear)="nextYear(ChartType.ActiveUsers)"
          ></app-chart>

          <!-- Application Completion Rate Chart -->
          <div>
            <app-chart
              [chartType]="chartTypeCompletionRate"
              [chartData]="applicationCompletionRate"
              [currentYear]="years[ChartType.ApplicationCompletion]"
              [chartTitle]="reportLabels[ChartType.ApplicationCompletion]"
              (previousYear)="previousYear(ChartType.ApplicationCompletion)"
              (nextYear)="nextYear(ChartType.ApplicationCompletion)"
            ></app-chart>
          </div>

          <div class="table-data">
            <div *ngIf="durationOfAccredition">
              <app-reports-table
                [data]="durationOfAccredition.data"
                [title]="durationOfAccredition.title"
                [headers]="durationOfAccredition.headers"
              ></app-reports-table>
            </div>

            <div *ngIf="durationOfSubmission">
              <app-reports-table
                [data]="durationOfSubmission.data"
                [title]="durationOfSubmission.title"
                [headers]="durationOfSubmission.headers"
              ></app-reports-table>
            </div>

            <div *ngIf="numberOfRequestsTable">
              <app-reports-table
                [data]="numberOfRequestsTable.data"
                [title]="numberOfRequestsTable.title"
                [headers]="numberOfRequestsTable.headers"
              ></app-reports-table>
            </div>
          </div>

          <!-- Withdrawn Application Rate Chart -->
          <app-chart
            [chartType]="chartTypeWithdrawalRate"
            [chartData]="withdrawnApplicationRate"
            [currentYear]="years[ChartType.WithdrawnApplications]"
            [chartTitle]="reportLabels[ChartType.WithdrawnApplications]"
            (previousYear)="previousYear(ChartType.WithdrawnApplications)"
            (nextYear)="nextYear(ChartType.WithdrawnApplications)"
          ></app-chart>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
