<app-header includeSubHeader="true"></app-header>
<div class="container">
  <div class="banner">
    <img [src]="banner" alt="banner" class="banner-img" />
  </div>

  <div class="main-container">
    <div>
      <div class="center-div services">
        أطلق المركز مبادرة اعتماد البرامج التدريبية في مجال كفاءة الطاقة والتي
        تم تطويرها بهدف تحسين جودة هذا النوع من البرامج التدريبية، وذلك لبناء
        منظومة تدريبية متميزة في مجالات كفاءة الطاقة حيث تسهم هذه المبادرة في
        تحقق المستفيدين من مناسبة البرامج التدريبية المنفذة وضمان جودتها​
      </div>
    </div>
    <div>
      <div class="center-div">
        <div>
          <p class="title-container">أهداف مبادرة اعتماد البرامج التدريبية</p>
          <div class="divider">
            <img [src]="divider" class="divider-img" />
          </div>
        </div>

        <div class="list">
          <div
            *ngFor="
              let item of trainingObjectives;
              let isLast = last;
              index as i
            "
          >
            <div [ngClass]="{ border: !isLast }" class="list-item">
              <div class="circle">{{ i + 1 }}</div>
              <p class="list-text">{{ item }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="center-div">
        <div>
          <p class="title-container">رحلة الحصول على الاعتماد التدريبي</p>
          <div class="divider">
            <img [src]="divider" class="divider-img" />
          </div>
        </div>
        <div class="stages-container">
          <div
            class="stages-wrapper"
            *ngFor="let item of stages; index as index"
          >
            <div class="stages">
              <div class="circle small-circle">{{ index + 1 }}</div>
              <img [src]="item.imgSrc" />
            </div>
            <div class="center-div">
              <p class="title">{{ item.title }}</p>
              <p class="description">{{ item.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center-div">
      <div>
        <p class="title-container">
          مسارات اعتماد البرامج التدريبية في مجال كفاءة الطاقة
        </p>
        <div class="divider">
          <img [src]="divider" class="divider-img" />
        </div>
      </div>
    </div>
    <div>
      <div class="box-buttons">
        <div
          class="box-button-item coming-soon"
          id="internal-application-request"
        >
          <div class="opacity">
            <img [src]="trainingApproval" class="box-button-img-1" />
            <p>
              البرامج التدريبية في مجال كفاءة الطاقة التي عمل المركز على إعدادها
            </p>
            <p class="click-here align-p">انقر هنا لتقديم الطلب</p>
          </div>
        </div>
        <div
          class="box-button-item hover-box"
          (click)="handleClick()"
          id="external-application-request"
        >
          <img [src]="trainingRequest" class="box-button-img-2" />
          <p>
            البرامج التدريبية في مجال كفاءة الطاقة التي قامت الجهات التدريبية
            بتأليفها
          </p>
          <p class="click-here">انقر هنا لتقديم الطلب</p>
        </div>
      </div>
    </div>
  </div>
</div>
