import { Component, OnInit } from '@angular/core';
import { ChartDataService } from '@app/dashboard/services/chart-data.service';
import { TableData } from '@app/dashboard/services/table-data.service';
import { Assets } from '@assets/images';
import { ReportCategory } from 'src/app/types/chart-reports';

@Component({
  selector: 'app-quarter-reports',
  templateUrl: './quarter-reports.component.html',
  styleUrl: './quarter-reports.component.scss',
})
export class QuarterReportsComponent implements OnInit {
  banner = Assets.banner;
  activeTabIndex: number = 0;
  chartType: string = 'bar';
  reportCategory = ReportCategory;

  //years
  years: { [key in ReportCategory]: number } = {
    [ReportCategory.AnnualReport]: new Date().getFullYear(),
    [ReportCategory.SemiAnnualReport]: new Date().getFullYear(),
    [ReportCategory.QuarterReport]: new Date().getFullYear(),
  };

  // Labels for reports
  reportLabels: { [key in ReportCategory]: string } = {
    [ReportCategory.AnnualReport]: 'عدد الاعتمادات السنوي',
    [ReportCategory.SemiAnnualReport]: 'عدد الاعتمادات النصفي',
    [ReportCategory.QuarterReport]: 'عدد الاعتمادات الربعي',
  };

  // Report data
  annualReport: any = null;
  semiAnnualReport: any = null;
  quarterReport: any = null;

  // Table data
  numberOfRequestsTable: TableData | null = null;
  averageTimeTable: TableData | null = null;

  constructor(private chartDataService: ChartDataService) {}

  ngOnInit(): void {
    this.loadData();
  }

  onTabChange(event: any): void {
    this.activeTabIndex = event.index;
    this.loadData();
  }

  mapAccreditationRequestType(tabIndex: number): number {
    return tabIndex === 0 ? 2 : 1;
  }

  loadData(): void {
    this.clearChartData();

    this.loadReport(ReportCategory.AnnualReport);
    this.loadReport(ReportCategory.SemiAnnualReport);
    this.loadReport(ReportCategory.QuarterReport);
  }

  loadReport(chart: ReportCategory): void {
    const year = this.years[chart];
    const accreditationRequestType = this.mapAccreditationRequestType(
      this.activeTabIndex,
    );

    this.chartDataService
      .getChartData(year, chart, accreditationRequestType)
      .subscribe(
        (data) => {
          switch (chart) {
            case ReportCategory.AnnualReport:
              this.annualReport = data.chart;
              break;
            case ReportCategory.SemiAnnualReport:
              this.semiAnnualReport = data.chart;
              break;
            case ReportCategory.QuarterReport:
              this.quarterReport = data.chart;
              break;
            default:
              console.error('Unknown report category');
          }
        },
        (error) => {
          console.error(`Error loading ${ReportCategory[chart]} data:`, error);
        },
      );
  }

  nextYear(chart: ReportCategory): void {
    this.changeYear(chart, 1);
  }

  previousYear(chart: ReportCategory): void {
    this.changeYear(chart, -1);
  }

  changeYear(chart: ReportCategory, increment: number): void {
    const newYear = this.years[chart] + increment;
    const currentYear = new Date().getFullYear();

    if (newYear > currentYear) {
      return;
    }

    if (newYear < 2000) {
      return;
    }

    this.years[chart] = newYear;
    this.loadReport(chart);
  }
  clearChartData(): void {
    this.annualReport = null;
    this.semiAnnualReport = null;
    this.quarterReport = null;
  }
}
