<div class="banner-container">
  <div class="welcome-text__container">
    <span class="welcome">مرحبا بك في </span>
    <h1 class="welcome-text">لوحة التحكم الرئيسية</h1>
  </div>
  <img [src]="banner" alt="banner" class="banner-image" />
</div>
<div class="container">
  <app-dialog
    [isCustomDialog]="true"
    [isClosable]="false"
    [visible]="isLoadingDialogOpen"
    [width]="'430px'"
  >
    <loading-spinner [isLoading$]="isModalLoading">
      <br />
      جاري معالجة طلبك...
    </loading-spinner>
  </app-dialog>

  <app-dialog
    [isCustomDialog]="true"
    [isClosable]="true"
    [visible]="isDialogOpen"
    [width]="'430px'"
    (onHide)="isDialogOpen = false"

  >
    <div class="success" *ngIf="!(isModalLoading | async)">
      <app-svg-icon class="success-icon" name="success-icon"></app-svg-icon>
      <span>{{ successMessage }}</span>
    </div>
  </app-dialog>

  <div>
    <p-tabView [activeIndex]="activeTabIndex" (onChange)="onTabChange($event)">
      <loading-spinner />

      <p-tabPanel header="قائمة المستخدمين الداخليين">
        <loading-spinner *ngIf="isLoading | async"></loading-spinner>
        <div
          *ngIf="selectedTabUserType === 1 && internalTableData?.data"
          class="table-container"
        >
          <app-table
            #tableComponent
            title="إدارة المستخدمين"
            [headers]="internalUsersHeaders"
            [data]="internalTableData.data"
            [options]="status"
            [totalRecords]="internalTableData?.totalRecords"
            [isGetUsers]="true"
            [showEyeButtonColumn]="false"
            [addUser]="true"
            (addUserEvent)="onAddNewUser()"
            (GetUserListEvent)="getUserList($event)"
            (viewAccountEvent)="viewAccountEventHandler($event)"
            (deactivateAccountEvent)="handleDeactivateAccount($event)"
          >
          </app-table>

          <!-- Modal for Adding User -->
          <p-dialog
            [(visible)]="showModal"
            modal="true"
            [draggable]="false"
            [closable]="false"
            (onHide)="closeModal()"
            [style]="{ width: '400px' }"
            [contentStyle]="{ 'text-align': 'center' }"
          >
            <div class="modal-content">
              <form [formGroup]="formGroupForAddUser" (ngSubmit)="saveUser()">
                <div class="form-group">
                  <label for="username">اسم المستخدم</label>
                  <input
                    id="username"
                    type="text"
                    pInputText
                    formControlName="username"
                  />
                  <div
                    class="error"
                    *ngIf="username?.touched && username?.errors"
                  >
                    <small *ngIf="username.errors['required']"
                      >اسم المستخدم مطلوب</small
                    >
                  </div>
                </div>

                <div class="form-group">
                  <label for="email">البريد الإلكتروني</label>
                  <input
                    id="email"
                    type="email"
                    pInputText
                    formControlName="email"
                  />
                  <div class="error" *ngIf="email?.touched && email?.errors">
                    <small *ngIf="email.errors['required']"
                      >البريد الإلكتروني مطلوب</small
                    >
                    <small *ngIf="email.errors['email']"
                      >الرجاء إدخال بريد إلكتروني صالح</small
                    >
                    <small *ngIf="email.errors['emailExists']"
                      >هذا البريد الإلكتروني مسجل مسبقًا</small
                    >
                  </div>
                </div>

                <div class="form-group">
                  <p-dropdown
                    appendTo="body"
                    class="full-dropdown"
                    [showClear]="true"
                    placeholder="صلاحيات المستخدم"
                    [options]="roles"
                    formControlName="selectedRole"
                  >
                    <ng-template pTemplate="item" let-role>
                      <div class="checkbox-item">
                        <input
                          type="radio"
                          [checked]="role.value === selectedRole?.value"
                          (change)="selectedRole?.setValue(role.value)"
                        />
                        <span class="label">{{ role.label }}</span>
                      </div>
                    </ng-template>
                  </p-dropdown>
                  <div
                    class="error"
                    *ngIf="selectedRole?.touched && selectedRole?.errors"
                  >
                    <small *ngIf="selectedRole.errors['required']"
                      >يرجى اختيار صلاحية</small
                    >
                  </div>
                </div>

                <div class="button-group">
                  <button
                    class="submit-button"
                    type="submit"
                    pButton
                    label="حفظ"
                  ></button>
                  <button
                    class="cancel-button"
                    type="button"
                    pButton
                    label="إلغاء"
                    (click)="closeModal()"
                  ></button>
                </div>
              </form>
            </div>
          </p-dialog>

          <!-- Modal for view internal account and assign ar remove role  -->

          <div class="user-profile-container">
            <p-dialog
              class="user-profile-dialog"
              modal="true"
              [draggable]="false"
              [(visible)]="userProfileVisible"
              [closable]="false"
              (onHide)="closeModal()"
              [style]="{ width: '490px' }"
              [baseZIndex]="10000"
            >
              <div class="user-profile">
                <img
                  src="assets/images/default.png"
                  alt="User Image"
                  class="profile-image"
                />
                <div class="user-details">
                  <div class="detail-row">
                    <span class="label">اسم المستخدم:</span>
                    <span class="value">{{ userDetails?.username }}</span>
                  </div>
                  <div class="detail-row">
                    <span class="label">البريد الإلكتروني:</span>
                    <span class="value">{{ userDetails?.email }}</span>
                  </div>
                  <div class="detail-row">
                    <span class="label">الدور الرئيسي:</span>
                    <span class="value">{{
                      userDetails?.userRoles[0]?.value
                    }}</span>
                  </div>
                  <div class="detail-row" *ngIf="hasAdditionalRoles">
                    <span class="label">الدور المسند</span>
                    <span class="value">{{
                      userDetails?.userRoles[1]?.value
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="assignable-roles" *ngIf="!hasAdditionalRoles">
                <p><strong>إسناد صلاحيات أخرى</strong></p>
                <form [formGroup]="formGroupForViewUser">
                  <p-dropdown
                    appendTo="body"
                    class="assignRole-dropdown"
                    [showClear]="true"
                    placeholder="صلاحيات المستخدم"
                    [options]="assignableRoles"
                    formControlName="selectedAssignableRole"
                  >
                    <ng-template pTemplate="item" let-role>
                      <div class="checkbox-item">
                        <input
                          type="radio"
                          [checked]="
                            role.value === selectedAssignableRole?.value
                          "
                          (change)="
                            selectedAssignableRole?.setValue(role.value)
                          "
                        />
                        <span class="label">{{ role.label }}</span>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </form>
              </div>

              <div class="dialog-footer">
                <button
                  pButton
                  label="{{
                    hasAdditionalRoles ? 'إلغاء الإسناد' : 'حفظ التغييرات'
                  }}"
                  (click)="
                    hasAdditionalRoles
                      ? removeUserRole(
                          formGroupForViewUser.get('selectedAssignableRole')
                            ?.value
                        )
                      : saveUserChanges()
                  "
                  class="save-button"
                ></button>
                <button
                  pButton
                  label="إغلاق"
                  (click)="closeModal()"
                  class="close-button"
                ></button>
              </div>
            </p-dialog>
          </div>
        </div>
      </p-tabPanel>

      <p-tabPanel header="قائمة المستخدمين الخارجيين">
        <loading-spinner *ngIf="isLoading | async"></loading-spinner>
        <div
          *ngIf="selectedTabUserType === 2 && externalTableData?.data"
          class="table-container"
        >
          <app-table
            #tableComponent2
            title="إدارة المستخدمين"
            [headers]="externalUsersHeaders"
            [data]="externalTableData.data"
            [totalRecords]="externalTableData?.totalRecords"
            [isGetUsers]="true"
            [showEyeButtonColumn]="false"
            (viewAccountEvent)="viewAccountEventHandler($event)"
            (deactivateAccountEvent)="handleDeactivateAccount($event)"
            (GetUserListEvent)="getUserList($event)"
          >
          </app-table>
        </div>

        <div class="user-profile-container-for-external-account">
          <p-dialog
            class="user-profile-dialog"
            modal="true"
            [draggable]="false"
            [(visible)]="externalUserProfileVisible"
            [closable]="true"
            (onHide)="closeModal()"
            [style]="{ width: '490px' }"
          >
            <div class="user-profile">
              <img
                src="assets/images/default.png"
                alt="User Image"
                class="profile-image"
              />
              <div class="user-details-container">
                <div class="detail-row">
                  <span class="label">اسم المستخدم:</span>
                  <span class="value">{{ externalUserDetails?.username }}</span>
                </div>
                <div class="detail-row">
                  <span class="label">البريد الإلكتروني:</span>
                  <span class="value">{{ externalUserDetails?.email }}</span>
                </div>
                <div class="detail-row">
                  <span class="label">اسم الجهة:</span>
                  <span class="value">{{
                    externalUserDetails?.organizationName
                  }}</span>
                </div>
              </div>
            </div>
          </p-dialog>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
