import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Assets } from '@assets/images';
import { MeasuringIndicatorsService } from '@app/dashboard/services/measuring-indicators.service';
import {
  TableService,
  TableData,
} from '@app/dashboard/services/table-data.service';
import { ChartDataService } from '@app/dashboard/services/chart-data.service';
import { ReportCategory } from 'src/app/types/chart-reports';
import { ChartType } from '@app/types/chart-reports';
import { ChartReportsComponent } from '../shared/chart-report/chart-report.component';
import { ReportsTableComponent } from '../shared/reports-table/reports-table.component';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { callAddFont } from '@assets/fonts/arabicLangForPDFExport/Vazirmatn-VariableFont_wght-normal';

interface ChartData {
  labels?: string[];
  datasets?: any[];
}

@Component({
  selector: 'app-executive-reports',
  templateUrl: './executive-reports.component.html',
  styleUrl: './executive-reports.component.scss',
})
export class ExecutiveReportsComponent implements OnInit {
  ChartType = ChartType;
  chartType: string = 'bar';
  reportCategory = ReportCategory;

  banner = Assets.banner;
  activeTabIndex: number = 0;

  // Chart display types
  chartTypeCompletionRate = 'pie';
  chartTypeWithdrawalRate = 'bar';

  @ViewChildren(ChartReportsComponent)
  charts!: QueryList<ChartReportsComponent>;

  // 2) Grab references to all <app-reports-table> children in your template
  @ViewChildren(ReportsTableComponent)
  tables!: QueryList<ReportsTableComponent>;

  // Use a dynamic object to manage years for each chart type
  yearsOfMeasuring: { [key in ChartType]: number } = {
    [ChartType.ActiveUsers]: new Date().getFullYear(),
    [ChartType.ApplicationCompletion]: new Date().getFullYear(),
    [ChartType.WithdrawnApplications]: new Date().getFullYear(),
  };

  yearsOfQuarter: { [key in ReportCategory]: number } = {
    [ReportCategory.AnnualReport]: new Date().getFullYear(),
    [ReportCategory.SemiAnnualReport]: new Date().getFullYear(),
    [ReportCategory.QuarterReport]: new Date().getFullYear(),
  };

  // Labels for each chart type
  measuringLabels: { [key in ChartType]: string } = {
    [ChartType.ActiveUsers]: 'عدد المستخدمين النشطين',
    [ChartType.ApplicationCompletion]: 'نسبة إكمال الطلبات',
    [ChartType.WithdrawnApplications]: 'معدل انسحاب الجهات من التقديم',
  };

  quarterLabels: { [key in ReportCategory]: string } = {
    [ReportCategory.AnnualReport]: 'عدد الاعتمادات السنوي',
    [ReportCategory.SemiAnnualReport]: 'عدد الاعتمادات النصفي',
    [ReportCategory.QuarterReport]: 'عدد الاعتمادات الربعي',
  };

  // Chart data for each chart type
  applicationCompletionRate: ChartData | null = null;
  withdrawnApplicationRate: ChartData | null = null;

  // Table data
  durationOfAccredition: TableData | null = null;
  durationOfSubmission: TableData | null = null;
  numberOfRequestsTable: TableData | null = null;

  //quarter report
  annualReport: any = null;
  semiAnnualReport: any = null;
  quarterReport: any = null;

  constructor(
    private measuringIndicatorsService: MeasuringIndicatorsService,
    private tableService: TableService,
    private chartDataService: ChartDataService,
  ) {}

  ngOnInit(): void {
    this.loadData(this.mapAccreditationRequestType(this.activeTabIndex));
  }

  onTabChange(event: { index: number }): void {
    this.activeTabIndex = event.index;
    this.loadData(this.mapAccreditationRequestType(this.activeTabIndex));
  }

  mapAccreditationRequestType(tabIndex: number): number {
    return tabIndex === 0 ? 2 : 1;
  }

  loadData(accreditationRequestType: number): void {
    // Clear previous data before loading new data
    this.clearChartData();

    // Load all charts
    this.loadMeasuringReports();

    //load quarter reports
    this.loadQuarterReports();

    // Load table data
    this.loadTables(accreditationRequestType);
  }
  private loadMeasuringReports(): void {
    this.loadMeasuringReport(ChartType.ActiveUsers);
    this.loadMeasuringReport(ChartType.ApplicationCompletion);
    this.loadMeasuringReport(ChartType.WithdrawnApplications);
  }

  private loadMeasuringReport(chart: ChartType): void {
    const year = this.yearsOfMeasuring[chart];
    const requestType = this.mapAccreditationRequestType(this.activeTabIndex);

    let request$;
    switch (chart) {
      case ChartType.ActiveUsers:
        request$ = this.measuringIndicatorsService.getActiveUsersData(year);
        break;
      case ChartType.ApplicationCompletion:
        request$ = this.measuringIndicatorsService.getApplicationCompletionRate(
          requestType,
          year,
        );
        break;
      case ChartType.WithdrawnApplications:
        request$ = this.measuringIndicatorsService.getWithDrawApplicationData(
          requestType,
          year,
        );
        break;
    }

    request$.subscribe((data) => {
      switch (chart) {
        case ChartType.ApplicationCompletion:
          this.applicationCompletionRate = data.chart;
          break;
        case ChartType.WithdrawnApplications:
          this.withdrawnApplicationRate = data.chart;
          break;
      }
    });
  }

  private loadQuarterReports(): void {
    this.loadQuarterReport(ReportCategory.AnnualReport);
    this.loadQuarterReport(ReportCategory.SemiAnnualReport);
    this.loadQuarterReport(ReportCategory.QuarterReport);
  }

  private loadQuarterReport(category: ReportCategory): void {
    const year = this.yearsOfQuarter[category];
    const requestType = this.mapAccreditationRequestType(this.activeTabIndex);

    this.chartDataService
      .getChartData(year, category, requestType)
      .subscribe((data) => {
        switch (category) {
          case ReportCategory.AnnualReport:
            this.annualReport = data.chart;
            break;
          case ReportCategory.SemiAnnualReport:
            this.semiAnnualReport = data.chart;
            break;
          case ReportCategory.QuarterReport:
            this.quarterReport = data.chart;
            break;
        }
      });
  }
  private loadTables(accreditationRequestType: number): void {
    this.measuringIndicatorsService
      .getDurationOfsubmitRequest(accreditationRequestType)
      .subscribe((data) => (this.durationOfAccredition = data));

    this.measuringIndicatorsService
      .getAverageTimeData(accreditationRequestType)
      .subscribe((data) => (this.durationOfSubmission = data));

    this.tableService
      .getNumberOfRequestsData(accreditationRequestType)
      .subscribe((data) => (this.numberOfRequestsTable = data));
  }

  public nextChartYear(chartType: ChartType): void {
    const newYear = this.yearsOfMeasuring[chartType] + 1;
    const currentYear = new Date().getFullYear();
    if (newYear <= currentYear) {
      this.yearsOfMeasuring[chartType] = newYear;
      this.loadMeasuringReport(chartType);
    }
  }

  public previousChartYear(chartType: ChartType): void {
    const newYear = this.yearsOfMeasuring[chartType] - 1;
    this.yearsOfMeasuring[chartType] = newYear;
    this.loadMeasuringReport(chartType);
  }

  public nextReportYear(reportCategory: ReportCategory): void {
    const newYear = this.yearsOfQuarter[reportCategory] + 1;
    const currentYear = new Date().getFullYear();
    if (newYear <= currentYear) {
      this.yearsOfQuarter[reportCategory] = newYear;
      this.loadQuarterReport(reportCategory);
    }
  }

  public previousReportYear(reportCategory: ReportCategory): void {
    const newYear = this.yearsOfQuarter[reportCategory] - 1;
    this.yearsOfQuarter[reportCategory] = newYear;
    this.loadQuarterReport(reportCategory);
  }

  // Clear all chart and table data before loading new data.
  private clearChartData(): void {
    this.applicationCompletionRate = null;
    this.withdrawnApplicationRate = null;
    this.annualReport = null;
    this.semiAnnualReport = null;
    this.quarterReport = null;
    this.durationOfAccredition = null;
    this.durationOfSubmission = null;
    this.numberOfRequestsTable = null;
  }

  exportAllMeasuringIndicators() {
    const pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'pt',
      format: 'a4',
    });

    jsPDF.API.events.push(['addFonts', callAddFont]);
    pdf.setFont('Vazirmatn-VariableFont_wght');
    pdf.setFontSize(16);

    let pageCount = 0;

    this.charts.forEach((chartComp) => {
      if (this.isMeasuringIndicatorChart(chartComp)) {
        const chartElement = chartComp.chartComponent?.chart
          ?.canvas as HTMLCanvasElement;

        if (
          !chartElement ||
          chartElement.width === 0 ||
          chartElement.height === 0
        ) {
          return;
        }

        if (pageCount > 0) {
          pdf.addPage();
        }

        const imgData = chartElement.toDataURL('image/png');
        const titleWithYear = `${chartComp.chartTitle} ${chartComp.currentYear}`;

        pdf.text(titleWithYear, 450, 80, { align: 'center' });

        pdf.addImage(
          imgData,
          'PNG',
          10,
          120,
          chartElement.width / 2,
          chartElement.height / 2,
        );

        pageCount++;
      }
    });

    this.tables.forEach((tableComp) => {
      if (this.isMeasuringIndicatorTable(tableComp)) {
        pdf.addPage();
        pageCount++;

        pdf.text(tableComp.title, 400, 50);

        const columns = tableComp.headers.map((header) => ({
          title: header.header,
          dataKey: header.field,
        }));

        const rows = tableComp.data.map((item) =>
          columns.map((col) => item[col.dataKey]),
        );

        autoTable(pdf, {
          head: [columns],
          body: rows,
          startY: 70,
          styles: {
            font: 'Vazirmatn-VariableFont_wght',
            halign: 'center',
          },
        });
      }
    });

    pdf.save('MeasuringIndicators.pdf');
  }

  exportAllSWOTReports() {
    const pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'pt',
      format: 'a4',
    });

    jsPDF.API.events.push(['addFonts', callAddFont]);
    pdf.setFont('Vazirmatn-VariableFont_wght');
    pdf.setFontSize(16);

    let pageCount = 0;

    this.charts.forEach((chartComp) => {
      if (this.isSWOTChart(chartComp)) {
        const chartElement = chartComp.chartComponent?.chart
          ?.canvas as HTMLCanvasElement;

        if (
          !chartElement ||
          chartElement.width === 0 ||
          chartElement.height === 0
        ) {
          return;
        }

        if (pageCount > 0) {
          pdf.addPage();
        }

        const imgData = chartElement.toDataURL('image/png');
        const titleWithYear = `${chartComp.chartTitle} ${chartComp.currentYear}`;

        pdf.text(titleWithYear, 450, 80, { align: 'center' });

        pdf.addImage(
          imgData,
          'PNG',
          10,
          120,
          chartElement.width / 2,
          chartElement.height / 2,
        );

        pageCount++;
      }
    });

    this.tables.forEach((tableComp) => {
      if (this.isSWOTTable(tableComp)) {
        pdf.addPage();
        pageCount++;

        pdf.text(tableComp.title, 50, 50);

        const columns = tableComp.headers.map((header) => ({
          title: header.header,
          dataKey: header.field,
        }));

        const rows = tableComp.data.map((item) =>
          columns.map((col) => item[col.dataKey]),
        );

        autoTable(pdf, {
          head: [columns],
          body: rows,
          startY: 70,
          styles: {
            font: 'Vazirmatn-VariableFont_wght',
            halign: 'center',
          },
        });
      }
    });

    pdf.save('SWOTReports.pdf');
  }

  private isMeasuringIndicatorChart(chartComp: ChartReportsComponent): boolean {
    return chartComp.category === 'measuring';
  }

  private isSWOTChart(chartComp: ChartReportsComponent): boolean {
    return chartComp.category === 'swot';
  }

  private isMeasuringIndicatorTable(tableComp: ReportsTableComponent): boolean {
    return tableComp.category === 'measuring';
  }

  private isSWOTTable(tableComp: ReportsTableComponent): boolean {
    return tableComp.category === 'swot';
  }
}
