<div class="footer-container">
  <div class="footer-info">
    <div class="logo-socials__container">
      <h2 class="footer-links__title">
        {{ 'منصات التواصل الاجتماعي' | translate }}
      </h2>

      <div class="icons-container">
        <div *ngFor="let social of socialIcons" class="icon">
          <a [href]="social.link" class="icons_link" [id]="social.id">
            <app-svg-icon [name]="social.icon" [ngClass]="icon"></app-svg-icon>
          </a>
        </div>
      </div>
    </div>
    <div class="footer-links">
      <h2 class="footer-links__title">
        {{ importantLinks.title | translate }}
      </h2>
      <ul class="footer-links__list">
        <li class="footer-links__item" *ngFor="let item of importantLinks.data">
          <a
            *ngIf="item.route; else externalLink"
            [routerLink]="item.route"
            [innerHTML]="sanitizeSvg(item.name | translate)"
            [id]="item?.id"
          >
          </a>
          <ng-template #externalLink>
            <a
              *ngIf="item.link"
              href="{{ item.link }}"
              [innerHTML]="sanitizeSvg(item.name | translate)"
              target="{{ item.newTab }}"
            >
            </a>
          </ng-template>
        </li>
      </ul>
    </div>
    <div class="footer-links">
      <h2 class="footer-links__title">{{ contactUs.title | translate }}</h2>
      <ul class="footer-links__list">
        <li
          class="footer-links__item contact-us-links"
          *ngFor="let item of contactUs.data; let i = index"
        >
          <a
            [id]="'contact-us-links' + i"
            [innerHTML]="sanitizeSvg(item | translate)"
          ></a>
        </li>
      </ul>
    </div>
  </div>
  <div class="footer-copyright">
    <div class="privacy-container">
      <a routerLink="/privacy-policy" class="bottom_links" id="privacy-policy">{{
        'privacy' | translate
      }}</a>
      <a routerLink="/usage-policy" class="bottom_links" id="usage-policy">
        {{ 'usagePolicy' | translate }}</a
      >
    </div>
    <span [innerHTML]="'rightsReserve' | translate" class="copyrights"></span>
  </div>
</div>
