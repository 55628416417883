<div class="banner-container">
    <div class="welcome-text__container">
      <span class="welcome">مرحبا بك في </span>
      <h1 class="welcome-text">لوحة التحكم الرئيسية</h1>
    </div>
    <img [src]="banner" alt="banner" class="banner-image" />
  </div>
  <div class="container">
    <div>
      <p-tabView [activeIndex]="activeTabIndex" (onChange)="onTabChange($event)">
        <loading-spinner />
  
        <p-tabPanel header="الطلبات للبرامج التي قامت الجهات التدريبية بتأليفها">
          <div class="subject">
            <span>قياس المؤشرات</span>
            <div class="btn-container">
              <button
                pButton
                type="button"
                label="تصدير"
                (click)="exportAllMeasuringIndicators()"
              >
                <i class="pi pi-download"></i>
              </button>
            </div>
          </div>
          <div>
            <!-- Application Completion Rate Chart -->
            <div>
              <app-chart
                [category]="'measuring'"
                [showExportBtn]="false"
                [chartType]="chartTypeCompletionRate"
                [chartData]="applicationCompletionRate"
                [currentYear]="yearsOfMeasuring[ChartType.ApplicationCompletion]"
                [chartTitle]="measuringLabels[ChartType.ApplicationCompletion]"
                (previousYear)="
                  previousChartYear(ChartType.ApplicationCompletion)
                "
                (nextYear)="nextChartYear(ChartType.ApplicationCompletion)"
              ></app-chart>
            </div>
  
            <div class="table-data">
              <div *ngIf="numberOfRequestsTable">
                <app-reports-table
                  [category]="'measuring'"
                  [showExportBtn]="false"
                  [data]="numberOfRequestsTable.data"
                  [title]="numberOfRequestsTable.title"
                  [headers]="numberOfRequestsTable.headers"
                ></app-reports-table>
              </div>
              <div *ngIf="durationOfAccredition">
                <app-reports-table
                  [category]="'measuring'"
                  [showExportBtn]="false"
                  [data]="durationOfAccredition.data"
                  [title]="durationOfAccredition.title"
                  [headers]="durationOfAccredition.headers"
                ></app-reports-table>
              </div>
  
              <div *ngIf="durationOfSubmission">
                <app-reports-table
                  [category]="'measuring'"
                  [showExportBtn]="false"
                  [data]="durationOfSubmission.data"
                  [title]="durationOfSubmission.title"
                  [headers]="durationOfSubmission.headers"
                ></app-reports-table>
              </div>
            </div>
  
            <!-- Withdrawn Application Rate Chart -->
            <app-chart
              [category]="'measuring'"
              [showExportBtn]="false"
              [chartType]="chartTypeWithdrawalRate"
              [chartData]="withdrawnApplicationRate"
              [currentYear]="yearsOfMeasuring[ChartType.WithdrawnApplications]"
              [chartTitle]="measuringLabels[ChartType.WithdrawnApplications]"
              (previousYear)="previousChartYear(ChartType.WithdrawnApplications)"
              (nextYear)="nextChartYear(ChartType.WithdrawnApplications)"
            ></app-chart>
          </div>
          <div class="subject">
            <span>تقرير التحليل الرباعي</span>
            <div class="btn-container">
              <button
                pButton
                type="button"
                label="تصدير"
                (click)="exportAllSWOTReports()"
              >
                <i class="pi pi-download"></i>
              </button>
            </div>
          </div>
          <div>
            <!-- Annual Report Chart -->
            <app-chart
              [category]="'swot'"
              [showExportBtn]="false"
              [chartType]="chartType"
              [chartData]="annualReport"
              [currentYear]="yearsOfQuarter[reportCategory.AnnualReport]"
              [chartTitle]="quarterLabels[reportCategory.AnnualReport]"
              (previousYear)="previousReportYear(reportCategory.AnnualReport)"
              (nextYear)="nextReportYear(reportCategory.AnnualReport)"
            ></app-chart>
  
            <!-- Semi-Annual Report Chart -->
            <div>
              <app-chart
                [category]="'swot'"
                [showExportBtn]="false"
                [chartType]="chartType"
                [chartData]="semiAnnualReport"
                [currentYear]="yearsOfQuarter[reportCategory.SemiAnnualReport]"
                [chartTitle]="quarterLabels[reportCategory.SemiAnnualReport]"
                (previousYear)="
                  previousReportYear(reportCategory.SemiAnnualReport)
                "
                (nextYear)="nextReportYear(reportCategory.SemiAnnualReport)"
              ></app-chart>
            </div>
  
            <!-- Quarter Report Chart -->
            <div>
              <app-chart
                [category]="'swot'"
                [showExportBtn]="false"
                [chartType]="chartType"
                [chartData]="quarterReport"
                [currentYear]="yearsOfQuarter[reportCategory.QuarterReport]"
                [chartTitle]="quarterLabels[reportCategory.QuarterReport]"
                (previousYear)="previousReportYear(reportCategory.QuarterReport)"
                (nextYear)="nextReportYear(reportCategory.QuarterReport)"
              ></app-chart>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="الطلبات للبرامج التي عمل المركز على إعدادها">
          <div class="subject">
            <span>قياس المؤشرات</span>
  
            <div class="btn-container">
              <button
                pButton
                type="button"
                label="تصدير"
                (click)="exportAllMeasuringIndicators()"
              >
                <i class="pi pi-download"></i>
              </button>
            </div>
          </div>
          <div>
            <div>
              <!-- Application Completion Rate Chart -->
              <div>
                <app-chart
                  [category]="'measuring'"
                  [showExportBtn]="false"
                  [chartType]="chartTypeCompletionRate"
                  [chartData]="applicationCompletionRate"
                  [currentYear]="
                    yearsOfMeasuring[ChartType.ApplicationCompletion]
                  "
                  [chartTitle]="measuringLabels[ChartType.ApplicationCompletion]"
                  (previousYear)="
                    previousChartYear(ChartType.ApplicationCompletion)
                  "
                  (nextYear)="nextChartYear(ChartType.ApplicationCompletion)"
                ></app-chart>
              </div>
  
              <div class="table-data">
                <div *ngIf="numberOfRequestsTable">
                  <app-reports-table
                    [category]="'measuring'"
                    [showExportBtn]="false"
                    [data]="numberOfRequestsTable.data"
                    [title]="numberOfRequestsTable.title"
                    [headers]="numberOfRequestsTable.headers"
                  ></app-reports-table>
                </div>
                <div *ngIf="durationOfAccredition">
                  <app-reports-table
                    [category]="'measuring'"
                    [showExportBtn]="false"
                    [data]="durationOfAccredition.data"
                    [title]="durationOfAccredition.title"
                    [headers]="durationOfAccredition.headers"
                  ></app-reports-table>
                </div>
  
                <div *ngIf="durationOfSubmission">
                  <app-reports-table
                    [category]="'measuring'"
                    [showExportBtn]="false"
                    [data]="durationOfSubmission.data"
                    [title]="durationOfSubmission.title"
                    [headers]="durationOfSubmission.headers"
                  ></app-reports-table>
                </div>
              </div>
            </div>
  
            <!-- Withdrawn Application Rate Chart -->
            <app-chart
              [category]="'measuring'"
              [showExportBtn]="false"
              [chartType]="chartTypeWithdrawalRate"
              [chartData]="withdrawnApplicationRate"
              [currentYear]="yearsOfMeasuring[ChartType.WithdrawnApplications]"
              [chartTitle]="measuringLabels[ChartType.WithdrawnApplications]"
              (previousYear)="previousChartYear(ChartType.WithdrawnApplications)"
              (nextYear)="nextChartYear(ChartType.WithdrawnApplications)"
            ></app-chart>
          </div>
          <div class="subject">
            <span>تقرير التحليل الرباعي </span>
            <div class="btn-container">
              <button
                pButton
                type="button"
                label="تصدير"
                (click)="exportAllSWOTReports()"
              >
                <i class="pi pi-download"></i>
              </button>
            </div>
          </div>
          <div>
            <!-- Annual Report Chart -->
            <app-chart
              [category]="'swot'"
              [showExportBtn]="false"
              [chartType]="chartType"
              [chartData]="annualReport"
              [currentYear]="yearsOfQuarter[reportCategory.AnnualReport]"
              [chartTitle]="quarterLabels[reportCategory.AnnualReport]"
              (previousYear)="previousReportYear(reportCategory.AnnualReport)"
              (nextYear)="nextReportYear(reportCategory.AnnualReport)"
            ></app-chart>
  
            <!-- Semi-Annual Report Chart -->
            <div>
              <app-chart
                [category]="'swot'"
                [showExportBtn]="false"
                [chartType]="chartType"
                [chartData]="semiAnnualReport"
                [currentYear]="yearsOfQuarter[reportCategory.SemiAnnualReport]"
                [chartTitle]="quarterLabels[reportCategory.SemiAnnualReport]"
                (previousYear)="
                  previousReportYear(reportCategory.SemiAnnualReport)
                "
                (nextYear)="nextReportYear(reportCategory.SemiAnnualReport)"
              ></app-chart>
            </div>
  
            <!-- Quarter Report Chart -->
            <div>
              <app-chart
                [category]="'swot'"
                [showExportBtn]="false"
                [chartType]="chartType"
                [chartData]="quarterReport"
                [currentYear]="yearsOfQuarter[reportCategory.QuarterReport]"
                [chartTitle]="quarterLabels[reportCategory.QuarterReport]"
                (previousYear)="previousReportYear(reportCategory.QuarterReport)"
                (nextYear)="nextReportYear(reportCategory.QuarterReport)"
              ></app-chart>
            </div>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>