<div class="banner-container">
  <div class="welcome-text__container">
    <span class="welcome">مرحبا بك في </span>
    <h1 class="welcome-text">لوحة التحكم الرئيسية</h1>
  </div>
  <img [src]="banner" alt="banner" class="banner-image" />
</div>
<div class="container">
  <div>
    <p-tabView [activeIndex]="activeTabIndex" (onChange)="onTabChange($event)">
      <p-tabPanel header="عدد الطلبات للبرامج التي قامت الجهات التدريبية بتأليفها">
        <div class="subject">
          <span>تقرير التحليل الرباعي</span>


          <!-- Annual Report Chart -->

          <app-chart
            [chartType]="chartType"
            [chartData]="annualReport"
            [currentYear]="years[reportCategory.AnnualReport]"
            [chartTitle]="reportLabels[reportCategory.AnnualReport]"
            (previousYear)="previousYear(reportCategory.AnnualReport)"
            (nextYear)="nextYear(reportCategory.AnnualReport)"
          ></app-chart>

          <!-- Semi-Annual Report Chart -->
          <div>
            <app-chart
              [chartType]="chartType"
              [chartData]="semiAnnualReport"
              [currentYear]="years[reportCategory.SemiAnnualReport]"
              [chartTitle]="reportLabels[reportCategory.SemiAnnualReport]"
              (previousYear)="previousYear(reportCategory.SemiAnnualReport)"
              (nextYear)="nextYear(reportCategory.SemiAnnualReport)"
            ></app-chart>
          </div>

          <!-- Quarter Report Chart -->
          <div>
            <app-chart
              [chartType]="chartType"
              [chartData]="quarterReport"
              [currentYear]="years[reportCategory.QuarterReport]"
              [chartTitle]="reportLabels[reportCategory.QuarterReport]"
              (previousYear)="previousYear(reportCategory.QuarterReport)"
              (nextYear)="nextYear(reportCategory.QuarterReport)"
            ></app-chart>
          </div>
        </div>
      </p-tabPanel>

      <p-tabPanel header="عدد الطلبات للبرامج التي عمل المركز على إعدادها">
        <div class="subject">
          <span>تقرير التحليل الرباعي</span>

          <!-- Annual Report Chart -->
          <app-chart
            [chartType]="chartType"
            [chartData]="annualReport"
            [currentYear]="years[reportCategory.AnnualReport]"
            [chartTitle]="reportLabels[reportCategory.AnnualReport]"
            (previousYear)="previousYear(reportCategory.AnnualReport)"
            (nextYear)="nextYear(reportCategory.AnnualReport)"
          ></app-chart>

          <!-- Semi-Annual Report Chart -->
          <div>
            <app-chart
              [chartType]="chartType"
              [chartData]="semiAnnualReport"
              [currentYear]="years[reportCategory.SemiAnnualReport]"
              [chartTitle]="reportLabels[reportCategory.SemiAnnualReport]"
              (previousYear)="previousYear(reportCategory.SemiAnnualReport)"
              (nextYear)="nextYear(reportCategory.SemiAnnualReport)"
            ></app-chart>
          </div>

          <!-- Quarter Report Chart -->
          <div>
            <app-chart
              [chartType]="chartType"
              [chartData]="quarterReport"
              [currentYear]="years[reportCategory.QuarterReport]"
              [chartTitle]="reportLabels[reportCategory.QuarterReport]"
              (previousYear)="previousYear(reportCategory.QuarterReport)"
              (nextYear)="nextYear(reportCategory.QuarterReport)"
            ></app-chart>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
