import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';

import { setLanguage } from '../utils/languageConfig';
import { AuthService } from './services/auth.service';
import { GoogleTagManagerService } from './services/gtm.service';

declare let dataLayer: any; // Declares the GTM data layer

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None, // Disable style encapsulation
})
export class AppComponent implements OnInit {
  router: string;

  constructor(
    private translate: TranslateService,
    private _router: Router,
    private gtmService: GoogleTagManagerService,
    private authService: AuthService,
  ) {
    // Set the default language and load translations
    translate.setDefaultLang('ar');
    // Set the language and direction
    setLanguage('ar', translate);
  }
  ngOnInit() {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        dataLayer.push({
          event: 'pageview',
          pagePath: event.urlAfterRedirects,
        });
      }
    });
    this.authService.authStatus.subscribe((isAuthenticated) => {
      // Re-initialize GTM with the updated auth status
      this.gtmService.initializeGTM(isAuthenticated);
      console.log('called', isAuthenticated);
    });
  }
}
