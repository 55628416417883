<div class="accreditation-application__container">
  <ng-container>
    <div class="title_container">
      <app-page-title
        *ngIf="!organizationId && !isRejectionLayout"
        [title]="'applyForProgramAccreditation'"
        [customClass]="'black-color'"
      ></app-page-title>
      <app-page-title
        *ngIf="reportId && organizationId && !isRejectionLayout"
        [customClass]="'application-number__title'"
        >{{ 'applicationNumber' | translate }}
        <span class="green-color">{{ reportId }}</span></app-page-title
      >
      <app-page-title
        *ngIf="isRejectionLayout"
        title="مسببات الرفض"
        [customClass]="'black-color'"
      />
      <app-custom-button
        *ngIf="isRenewable"
        buttonText="تجديد طلب الإعتماد"
        buttonStyle="greenButton"
        (click)="renewApplication()"
      />
    </div>
  </ng-container>

  <app-dialog
    [isCustomDialog]="true"
    [isClosable]="false"
    [visible]="isDialogOpen"
    [width]="'430px'"
  >
    <loading-spinner [isLoading$]="isLoading">
      <br />
      جاري إنشاء طلب تجديد الإعتماد ...</loading-spinner
    >
    <div class="success" *ngIf="!(isLoading | async)">
      <app-svg-icon class="success-icon" name="success-icon" />
      <span
        >تم انشاء طلب تجديد الإعتماد بنجاح<br />جاري الانتقال الى صفحة الطلب
      </span>
    </div>
  </app-dialog>

  <app-breadcrumbs [levels]="levels"></app-breadcrumbs>
  <app-form-tabs
    (reportIdEmitter)="setReportId($event)"
    (renewableApplicationEmitter)="isRenewable = $event"
    (showRejectionEmitter)="showRejection($event)"
    [tabs]="tabs"
    [(activeTabIndex)]="activeTabIndex"
    [showRejectionReason]="isRejectionLayout"
    [typeOfProg]="typeOfProg"

  >
  </app-form-tabs>
</div>
