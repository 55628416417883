import { Component } from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeHtml,
} from '@angular/platform-browser';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public logoSvg!: SafeResourceUrl;

  importantLinks = {
    title: 'importantLinks',
    data: [
      {
        name: 'المركز السعودي لكفاءة الطاقة',
        link: 'https://www.seec.gov.sa/',
        route: '',
        newTab: '_blank',
      },
      {
        name: 'منصة التدريب',
        link: 'https://training.seec.gov.sa/courses-for-trainee',
        route: '',
        newTab: '_blank',
      },
      {
        name: 'الأسئلة الشائعة',
        route: '/faq',
        link: '',
        id: 'common-questions',
      },
    ],
  };

  contactUs = {
    title: 'contactUS',
    data: ['info@seec.gov.sa', '920002742'],
  };

  socialIcons = [
    {
      icon: 'x-icon',
      link: 'https://twitter.com/SeecSaudi',
      id: 'x-social-media',
    },
    {
      icon: 'linkedin-icon',
      link: 'https://www.linkedin.com/company/saudi-energy-efficiency-program/?originalSubdomain=ae&originalSubdomain=ae',
      id: 'linkedin',
    },
  ];

  constructor(private sanitizer: DomSanitizer) {
    this.logoSvg = this.sanitizer.bypassSecurityTrustResourceUrl(
      'assets/images/logo.svg',
    );
  }

  sanitizeSvg(svgString: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(svgString);
  }
}
